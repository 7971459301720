import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { useFormik } from 'formik';
import { pageValidation } from './../../../config/formValidationSchema/';
import { apiRequest } from '../../../api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import * as SectionReducer from './SectionReducer';
import JoditEditor from 'jodit-react';

const initialValues = {
    title: "",
    sku: "",
    body: "",
    status: ""
}

const error = {
    title: "",
    sku: "",
    body: "",
    status: ""
}

const EditMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [values, setFormData] = useState({ ...initialValues });
    const [errors, setError] = useState({ ...error });
    const pathname = window.location.pathname.split('/');
    const sectionId = pathname[pathname.length - 1];
    const editor = useRef(null);
    const [content, setContent] = useState('');

    const config = {
        placeholder: "Start Typing..."
    }

    // let { values, errors, handleSubmit, handleChange, handleBlur } = useFormik({
    //     initialValues: formData,
    //     validationSchema: pageValidation,
    //     onSubmit: (values) => {
    //         setLoading(true);
    //         apiRequest({
    //             method: 'PUT',
    //             url: `/page/${formData.id}`,
    //             data: values
    //         }).then(() => {
    //             setLoading(false);
    //             navigate('/pages')
    //             getPages();
    //         }).catch((error) => {
    //             Swal.fire({
    //                 icon: "error",
    //                 title: "Oops...",
    //                 text: error.message,
    //                 width: 400
    //             });
    //         })
    //     }
    // })

    // values = {...formData};

    const getSectionLists = () => {
        apiRequest({
            method: "GET",
            url: "section"
        }).then(resp => {
            dispatch(SectionReducer.actions.sectionList(resp.data));
        }).catch(error => {
            dispatch(SectionReducer.actions.sectionList([]));
        });
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setFormData({
            ...values,
            [name]: value
        })

        if(name == 'title') {
            setError({
                ...errors,
                title: ''
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let flag = true;
        if (!values.title) {
            setError({
                ...errors,
                title: "Title is required"
            })
            flag = false;
        }

        if (flag) {
            setLoading(true);
            apiRequest({
                method: 'PUT',
                url: `/section/${values.id}`,
                data: {
                    ...values,
                    body: editor.current.value
                }
            }).then(() => {
                setLoading(false);
                navigate('/sections')
                getSectionLists();
            }).catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                    width: 400
                });
            })
        }
    }


    useEffect(() => {
        apiRequest({
            method: "GET",
            url: `/section/${sectionId}`
        }).then(resp => {
            setFormData(resp.data);
        }).catch(error => {
            console.log("get all section list error", error);
        });
    }, [])

    return (
        <>
            {/* <div className="container-fluid" style={{marginTop: "-20px", marginBottom: "50px"}}> */}
            <div className="container">
                <div className="row event-list-wrapper">
                    <div className="col-12 col-sm-12">
                        <p className='text-danger fw-bold fs-4'>Edit Section</p>
                        <form className="" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Sectino Title</label>
                                <input type="text" className="form-control" id="title" name="title" placeholder="Menu Title" value={values.title} onChange={handleChange} />
                                <p className='text-danger'>{errors.title}</p>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="css" className="form-label">Section CSS</label>
                                <textarea type="text" className="form-control" id="css" name="css" placeholder="Section CSS" rows="10" value={values.css} onChange={handleChange} autoComplete = "off" ></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="body" className="form-label">Section Body</label>
                                <JoditEditor
                                    ref={editor}
                                    value={values.body}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newContent => {}}
                                />
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="body" className="form-label">Menu Body</label>
                                <textarea type="text" className="form-control" id="body" name="body" placeholder="Menu Body" rows="10" value={content} autoComplete = "off" ></textarea>
                            </div> */}
                            {/* <div className="mb-3">
                                <label htmlFor="body" className="form-label">Menu Body</label>
                                <textarea type="text" className="form-control" id="body" name="body" placeholder="Menu Body" rows="10" value={values.body} onChange={handleChange}></textarea>
                            </div> */}
                            <div className="mb-3">
                                <label htmlFor="active" className="form-label">Status(Active/Inactive): </label>
                                <div className='d-flex'>
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" id="active" name="status" value="ACTIVE" onChange={handleChange} checked={values.status === 'ACTIVE'} />Active
                                    </div>
                                    <div className="form-check mx-3">
                                        <input type="radio" className="form-check-input" id="inactive" name="status" value="INACTIVE" onChange={handleChange} checked={values.status === 'INACTIVE'} />Inactive
                                    </div>
                                </div>
                                <p className='text-danger'>{errors.status}</p>
                            </div>
                            <div className="row">
                                <div className="col-9 col-sm-9">
                                    <button type="submit" className='btn btn-success p-0 px-2 py-1' disabled = {loading}>
                                        {
                                            loading ? (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            ) : (
                                                'Save'
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default EditMenu;