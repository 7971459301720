import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { apiRequest } from '../../../api';
import CaptionEditor from './editor';
import configData from '../../../config/config';
import './style.css';


const SliderEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const editor = useRef();
    const defaultContent = `<p class="heading-3">Caption Title</p>
    <p>Caption content</p>`;
    const pathname = location.pathname.split('/');
    const [sliderData, setSliderData] = useState({
        title: '',
        sub_title: '',
        button_one: '',
        button_two: '',
        image_list: [],
        status: 'ACTIVE',
    });

    const getSliderData = () => {
        apiRequest({
            method: "GET",
            url: `slider/${pathname[pathname.length - 1]}`,
        }).then(resp => {
            setSliderData(resp.data);
        }).catch(error => {
            console.log(error);
        });
    };
    useEffect(() => {
        getSliderData();
    }, []);
    const addSlides = () => {
        const data = {...sliderData};
        data.image_list.push({
            title: '',
            subTitle: '',
            buttonOneText: '',
            buttonOneUrl: '',
            buttonTwoText: '',
            buttonTwoUrl: '',
            webImage: '',
            mobileImage: '',
            caption: ''
        });
        setSliderData(data);
    };
    const deleteImage = (index, type) => {
        const data = {...sliderData};
        data.image_list[index][type] = '';
        setSliderData(data);
    };
    const handleImage = (e, index, type) => {
        const data = {...sliderData};
        apiRequest({
            method: "POST",
            url: 'image',
            data: { image: e.target.files[0], title: sliderData.title },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(resp => {
            data.image_list[index][type] = resp.data.full_path;

            setTimeout(() => {
                setSliderData(data);
            }, 5000);

        }).catch(error => {
            console.log(error);
        });
    };
    const handleTextChange = (e, index) => {
        const data = {...sliderData};
        if (index != undefined) {
            data.image_list[index][e.target.name] = e.target.value;
        }else{
            data.title = e.target.value;
        }
        setSliderData(data);
    };
    const handleChangeEditor = (content, index) => {
        const data = {...sliderData};
        data.image_list[index]['caption'] = content;
        setSliderData(data);
    };
    const addSample = (index) => {
        const data = {...sliderData};
        data.image_list[index]['caption'] = defaultContent;
        setSliderData(data);
    };
    const deleteSlide = (index) => {
        const data = {...sliderData};
        const imgList = [...data.image_list];
        imgList.splice(index, 1);
        setSliderData({
            ...data,
            image_list: [...imgList]
        });
    };
    const saveSlider = () => {
        apiRequest({
            method: "PUT",
            url: `slider/${pathname[pathname.length - 1]}`,
            data: {...sliderData}
        }).then(resp => {
            navigate('/sliders')
        }).catch(error => {
            console.log(error);
        });
    };
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className="list-group">
                                <div className="list-group-item active" aria-current="true">
                                    Add Sliders
                                </div>
                                <form className="">
                                    <div className="mb-3">
                                        <label className="form-label">Slider Title</label>
                                        <input type="text" className="form-control" name="title" placeholder="Slider Title" value={sliderData.title} onChange={handleTextChange} autoComplete="off" />
                                    </div>
                                    {sliderData.image_list.length > 0 && sliderData.image_list.map((item, index) => (<div className="fluid-100 cards" key={`slides_${index}`}>
                                        <div className="mb-3 d-flex justify-content-between align-items-center">
                                            <label className="form-label">Slide {index + 1} Details</label>
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => deleteSlide(index)}
                                            >
                                                <i className="icon-trash"></i>
                                            </button>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Title</label>
                                            <input type="text" className="form-control" name="title" placeholder="Title" value={item.title} onChange={(e) => handleTextChange(e, index)} autoComplete="off" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Sub Title</label>
                                            <input type="text" className="form-control" name="subTitle" placeholder="Sub Title" value={item.subTitle} onChange={(e) => handleTextChange(e, index)} autoComplete="off" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Button One Text</label>
                                            <input type="text" className="form-control" name="buttonOneText" placeholder="Button One Text" value={item.buttonOneText} onChange={(e) => handleTextChange(e, index)} autoComplete="off" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Button One Url</label>
                                            <input type="text" className="form-control" name="buttonOneUrl" placeholder="Button One Url" value={item.buttonOneUrl} onChange={(e) => handleTextChange(e, index)} autoComplete="off" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Button Two Text</label>
                                            <input type="text" className="form-control" name="buttonTwoText" placeholder="Button Two Text" value={item.buttonTwoText} onChange={(e) => handleTextChange(e, index)} autoComplete="off" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Button Two Url</label>
                                            <input type="text" className="form-control" name="buttonTwoUrl" placeholder="Button Two Url" value={item.buttonTwoUrl} onChange={(e) => handleTextChange(e, index)} autoComplete="off" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Top Caption
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => addSample(index)}
                                                >
                                                    Add sample content
                                                </button>
                                            </label>
                                            <CaptionEditor
                                                handleChangeEditor={handleChangeEditor}
                                                value={item.caption}
                                                index={index}
                                            />
                                            {/* <JoditEditor
                                                ref={editor}
                                                value={item.caption}
                                                tabIndex={1}
                                                onChange={(newContent) => handleChangeEditor(newContent, index)}
                                            /> */}
                                        </div>

                                        {item.webImage ? (<div className="mb-3 img-thumb">
                                            <img src={`${configData.imageUrl}${item.webImage}`} className="img-fluid" />
                                            <button type="button" onClick={() => deleteImage(index, 'webImage')}>
                                                <i className="icon-close"></i>
                                            </button>
                                        </div>) : (<div className="mb-3">
                                            <label className="form-label">Web Image</label>
                                            <input type="file" className="form-control" name="webImage" value="" onChange={(e) => handleImage(e, index, 'webImage')} />
                                        </div>)}

                                        {item.mobileImage ? (<div className="mb-3 img-thumb">
                                            <img src={`${configData.imageUrl}${item.mobileImage}`} className="img-fluid" />
                                            <button type="button" onClick={() => deleteImage(index, 'mobileImage')}>
                                                <i className="icon-close"></i>
                                            </button>
                                        </div>) : (<div className="mb-3">
                                            <label className="form-label">Mobile Image</label>
                                            <input type="file" className="form-control" name="mobileImage" value="" onChange={(e) => handleImage(e, index, 'mobileImage')} />
                                        </div>)}
                                    </div>))}
                                    <div className="mb-3 d-flex justify-content-between fluid-100">
                                        <button
                                            type="button"
                                            onClick={addSlides}
                                            className="btn btn-success p-0 px-2 py-1"
                                        >
                                            Add Slides
                                        </button>
                                        <button
                                            type="button"
                                            className='btn btn-success p-0 px-2 py-1'
                                            onClick={saveSlider}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SliderEdit;