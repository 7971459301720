const initialState = {
    eventList: []
};


export const actionTypes = {
    eventList: '[EventList] Action'
}
  
export const EventReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.eventList: {
            return {
                ...state,
                eventList: action.payload
            }
        }
        default: { 
            return state;
        }
    }
}

export const actions = {
    eventList: (eventList) => {
        return {
            type: actionTypes.eventList,
            payload: eventList
        }
    }
}