import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../../../api';
// import constant from '../../../config/config';
import './style.css';

const DealerAdd = () => {
    const [inputFields, setInputFields] = useState([
        {
            name: 'dealercode', value: '', label: 'Dealer Code', type: 'input'
        },
        {
            name: 'name', value: '', label: 'Name', type: 'input'
        },
        {
            name: 'address', value: '', label: 'Address', type: 'input'
        },
        {
            name: 'city', value: '', label: 'City', type: 'input'
        },
        {
            name: 'state', value: '', label: 'State', type: 'input'
        },
        {
            name: 'phone_no', value: '', label: 'Phone No.', type: 'input'
        },
        {
            name: 'alternative_phoneno', value: '', label: 'Alternate Phone No.', type: 'input'
        },
        {
            name: 'email', value: '', label: 'Email.', type: 'email'
        },
        {
            name: 'alternative_email', value: '', label: 'Alternate Email', type: 'email'
        },
        {
            name: 'latitude', value: '', label: 'Latitude', type: 'input'
        },
        {
            name: 'longitude', value: '', label: 'Longitude', type: 'input'
        },
        {
            name: 'status', value: 1, label: 'Status ( Active - 1, Inactive - 0 )', type: 'input'
        },
        {
            name: 'thumbnail_image', value: '', label: 'Thumb Image', type: 'file'
        },
    ]);
    const navigate = useNavigate();
    const imageChange = (event, inputIndex) => {
        apiRequest({
            method: "post",
            url: "image",
            data: {image: event.target.files[0], title: 'dealer'} ,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(resp => {
            const input = {...inputFields[inputIndex]};
            input.value = resp.data.full_path;
            const updatedFields = [...inputFields];
            updatedFields[inputIndex] = input;
            setInputFields(updatedFields);
        }).catch(error => {
            console.log(error);
        });
    };
    const deleteImage = (inputIndex) => {
        const input = {...inputFields[inputIndex]};
        input.value = '';
        const updatedFields = [...inputFields];
        updatedFields[inputIndex] = input;
        setInputFields(updatedFields);
    };
    const inputChangeHandler = (event, index, item) => {
        const input = {...inputFields[index]};
        input.value = event.target.value;
        const updatedFields = [...inputFields];
        updatedFields[index] = input;
        setInputFields(updatedFields);
    };
    const focusHandler = (event, index) => {
        if(!event.target.value && event.target.name === 'sku'){
            const input = {...inputFields[index]};
            const title = {...inputFields[0]};
            input.value = title.value.replaceAll(' ', '-');
            const updatedFields = [...inputFields];
            updatedFields[index] = input;
            setInputFields(updatedFields);
        }
    };
    const submitForm = () => {
        const formData = {};
        inputFields.forEach(item => {
            if(item.name === 'status'){
                formData[item.name] = item.value === 1 ? 'ACTIVE' : 'INACTIVE';
            }else if(item.type === 'editor'){
                const editorContent = item.ref.current.getContent();
                formData[item.name] = editorContent.replaceAll('\r\n', '').replaceAll('\n', '');
            }else{
                formData[item.name] = item.value;
            }
        });
        apiRequest({
            method: "post",
            url: "dealers",
            data: formData,
        }).then(resp => {
            navigate('/dealer');
        }).catch(error => {
        });
    };
    const setEditorRef = (editor, item, index) => {
        const fields = [...inputFields];
        const editorInput = fields[index];
        editorInput.ref.current = editor;
        fields[index] = editorInput;
        setInputFields(fields);
    };


    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className="list-group">
                                <div className="list-group-item active" aria-current="true">
                                    Add Dealer
                                </div>
                                <form className="">
                                    {inputFields.map((item, index) => {
                                        if(item.type === 'file'){
                                            if(item.value){
                                                return (
                                                    <div className="mb-3 img-thumb" key={`input_${index}`}>
                                                        <img src={item.value} className="img-fluid" />
                                                        <button type="button" onClick={() => deleteImage(index)}>
                                                            <i className="icon-close"></i>
                                                        </button>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div className="mb-3" key={`input_${index}`}>
                                                    <label className="form-label">{item.label}</label>
                                                    <input type={item.type} className="form-control" onChange={(event) => imageChange(event, index)}/>
                                                </div>
                                            );
                                        }else{
                                            return (
                                                <div className="mb-3" key={`input_${index}`}>
                                                    <label htmlFor={item.name} className="form-label">{item.label}</label>
                                                    <input type={item.type} name={item.name} className="form-control" id={item.name} placeholder={item.label} value={item.value} onChange={(event) => inputChangeHandler(event, index, item)} onFocus={event => focusHandler(event, index)}/>
                                                </div>
                                            )
                                        }
                                    })}
                                    <div className="mb-3">
                                        <button type="button" onClick={submitForm} className="btn btn-primary">Add Dealer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DealerAdd;