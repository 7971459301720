import React, { useState } from 'react'
import { Switch } from 'antd';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../../api';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import * as MenuReducer from './MenuReducer';

export const TableRow = ({ item, index }) => {
    const [loading, setLoading] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    const dispatch = useDispatch();

    const getMenuLists = () => {
        apiRequest({
            method: "GET",
            url: "menu"
        }).then(resp => {
            dispatch(MenuReducer.actions.menuList(resp.data));
        }).catch(error => {
            console.log(error);
        });
    };

    const handleCheckedClick = () => {
        setLoading(true);
        apiRequest({
            method: 'PUT',
            url: `/menu/${item.id}`,
            data: {
                ...item,
                status: `${item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`
            }
        }).then(() => {
            setLoading(false);
            getMenuLists();
        }).catch((error) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
        })
    }

    const handleDelete = () => {
        setDelLoading(true);
        apiRequest({
            method: 'DELETE',
            url: `/menu/${item.id}`
        }).then((res) => {
            setDelLoading(false);
            getMenuLists();
        }).catch((error) => {
            setDelLoading(false);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
        })
    }

    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{item.title}</td>
            <td>{`${item.sku.substring(0,25)}${item.sku.length > 25 ? '...' : ''}`}</td>
            <td>{item.updated_by}</td>
            <td>
                <Link to={`/menus/edit/${btoa(item.sku)}`}><i className="icon-pencil"></i></Link>
                {/* {
                    delLoading ? (
                        <span className='indicator-progress'>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ) : (
                        <button type="button" onClick={handleDelete}>
                            <i className="icon-trash"></i>
                        </button>
                    )
                } */}
            </td>
            {/* <td>
                <Switch checked={item.status === 'ACTIVE' ? true : false} onClick={handleCheckedClick} loading={loading} />
            </td> */}
        </tr>
    )
}
