import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../../../api';
import constant from '../../../config/config';
import './style.css';
import Swal from 'sweetalert2';

const EventAdd = () => {
    const [inputFields, setInputFields] = useState([
        {
            name: 'title', value: '', label: 'Title', type: 'input'
        },
        {
            name: 'sku', value: '', label: 'SKU', type: 'input'
        },
        {
            name: 'event_time', value: '', label: 'Event Time', type: 'date'
        },
        {
            name: 'status', value: 1, label: 'Status ( Active - 1, Inactive - 0 )', type: 'input'
        },
        {
            name: 'image_list', value: [
                {
                    name: 'image', value: '', label: 'Event Images', type: 'file'
                }
            ]
        }
    ]);
    const navigate = useNavigate();
    // const getBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = error => reject(error);
    //     });
    // }
    const addImageInput = (index) => {
        const img = { ...inputFields[index] };
        img.value.push({
            name: 'image', value: '', label: 'Event Images', type: 'file'
        });
        const updatedFields = [...inputFields];
        updatedFields[index] = img;
        setInputFields(updatedFields);
    };
    const imageChange = (event, imgIndex, inputIndex) => {
        const titleField = inputFields.find(item => item.name === 'title');
        apiRequest({
            method: "POST",
            url: 'image',
            data: { image: event.target.files[0], title: titleField.value },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(resp => {
            const input = { ...inputFields[inputIndex] };
            input.value[imgIndex].value = resp.data.full_path;
            const updatedFields = [...inputFields];
            updatedFields[inputIndex] = input;
            setInputFields(updatedFields);
        }).catch(error => {
            console.log(error);
        });
    };
    const deleteImage = (imgIndex, inputIndex) => {
        const input = { ...inputFields[inputIndex] };
        input.value[imgIndex].value = '';
        const updatedFields = [...inputFields];
        updatedFields[inputIndex] = input;
        setInputFields(updatedFields);
    };
    const inputChangeHandler = (event, index) => {
        const input = { ...inputFields[index] };
        input.value = event.target.value;
        const updatedFields = [...inputFields];
        updatedFields[index] = input;
        // setInputFields(updatedFields);

                // Update SKU field if the changed field is 'title'
        if (input.name === 'title') {
            const skuField = updatedFields.find(item => item.name === 'sku');
            skuField.value = event.target.value.replaceAll(' ', '-');
        }

        setInputFields(updatedFields);
        
    };
    const focusHandler = (event, index) => {
        if (!event.target.value && event.target.name === 'sku') {
            const input = { ...inputFields[index] };
            const title = { ...inputFields[0] };
            input.value = title.value.replaceAll(' ', '-');
            const updatedFields = [...inputFields];
            updatedFields[index] = input;
            setInputFields(updatedFields);
        }
    };
    const submitForm = () => {
        const formData = {};
        inputFields.forEach(item => {
            if (item.name === 'image_list') {
                formData[item.name] = [];
                item.value.forEach(itm => {
                    formData[item.name].push({
                        url: itm.value,
                        caption: ''
                    });
                });
            } else if (item.name === 'status') {
                formData[item.name] = item.value === 1 ? 'ACTIVE' : 'INACTIVE';
            } else {
                formData[item.name] = item.value;
            }
        });
        apiRequest({
            method: "post",
            url: "events",
            data: formData,
        }).then(resp => {
            navigate('/events');
        }).catch(error => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
        });
    };
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <p>Add Event</p>
                            <form className="">
                                {inputFields.map((item, index) => {
                                    if (item.name === 'image_list') {
                                        return (
                                            <div className="row" key={`input_${index}`}>
                                                <div className="col-9 col-sm-9">
                                                    {item.value.map((itm, ind) => {
                                                        if (itm.value) {
                                                            return (
                                                                <div className="mb-3 img-thumb" key={`input_${index}_${ind}`}>
                                                                    <img src={`${itm.value}`} className="img-fluid" />
                                                                    <button type="button" onClick={() => deleteImage(ind, index)}>
                                                                        <i className="icon-close"></i>
                                                                    </button>
                                                                </div>
                                                            );
                                                        }
                                                        return (
                                                            <div className="mb-3" key={`input_${index}_${ind}`}>
                                                                <label className="form-label">{itm.label}</label>
                                                                <input type={itm.type} className="form-control" onChange={(event) => imageChange(event, ind, index)} />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="col-3 col-sm-3 d-flex align-items-end justify-content-end">
                                                    <div className="mb-3 d-flex flex-column align-items-end">
                                                        <label className="form-label">&nbsp;</label>
                                                        <button type="button" onClick={() => addImageInput(index)}>
                                                            <i className="icon-plus"></i> Add Image
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="mb-3" key={`input_${index}`}>
                                                <label htmlFor={item.name} className="form-label">{item.label}</label>
                                                <input type={item.type} name={item.name} className="form-control" id={item.name} placeholder={item.label} value={item.value} onChange={(event) => inputChangeHandler(event, index)} onFocus={event => focusHandler(event, index)} />
                                            </div>
                                        );
                                    }
                                })}
                                <div className="row">
                                    <div className="col-6 col-sm-6">
                                        <div className="mb-3">
                                            <button type="button" onClick={submitForm} className="btn btn-success">Save Event</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventAdd;