import React, { useState } from 'react'
import { Switch } from 'antd';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../../api';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import * as DealerReducer from './DealerReducer';

export const TableRow = ({ item, index }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const getDealerList = () => {
        apiRequest({
            method: 'GET',
            url: 'dealers'
        })
        .then((resp) => {
            dispatch(DealerReducer.actions.dealerList(resp.data));
        })
        .catch((error) => {
            console.log("getting all dealers error", error)
        })
    }

    const handleCheckedClick = () => {
        setLoading(true);
        apiRequest({
            method: 'PUT',
            url: `/dealers/${item.id}`,
            data: {
                ...item,
                status: `${item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`
            }
        }).then(() => {
            setLoading(false);
            getDealerList();
        }).catch((error) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
        })
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this item.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                apiRequest({
                    method: 'DELETE',
                    url: `/dealers/${item.id}`
                }).then(() => {
                    setLoading(false);
                    getDealerList();
                    window.location.reload(); // Reload the page after deletion

                }).catch((error) => {
                    setLoading(false); // Make sure to set loading state to false on error
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error.message,
                        width: 400
                    });
                });
            }
        });
    }


    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{item.dealercode}</td>
            <td>{item.name}</td>
            <td>{item.state}</td>
            <td>{item.city}</td>
            <td>{item.status}</td>
            <td>{item.updated_by }</td> 

            <td>
                <Link to={`/dealer/edit/${item.id}`}><i className="icon-pencil"></i></Link>
                {
                    loading ? (
                        <span className='indicator-progress'>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ) : (
                        <button type="button" onClick={handleDelete}>
                            <i className="icon-trash"></i>
                        </button>
                    )
                }
            </td>
            {/* <td>
                <Switch checked={item.status === 'ACTIVE' ? true : false} onClick={handleCheckedClick} loading={loading} />
            </td> */}
        </tr>
    )
}
