import React, { useState } from 'react'
import { Switch } from 'antd';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../../api';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import * as SectionReducer from './SectionReducer';

export const TableRow = ({ item, index }) => {
    const [loading, setLoading] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    const dispatch = useDispatch();

    const getSectionList = () => {
        apiRequest({
            method: "GET",
            url: "section"
        }).then(resp => {
            dispatch(SectionReducer.actions.sectionList(resp.data));
        }).catch(error => {
            dispatch(SectionReducer.actions.sectionList([]));
        });
    };

    const handleCheckedClick = () => {
        setLoading(true);
        apiRequest({
            method: 'PUT',
            url: `/section/${item.id}`,
            data: {
                ...item,
                status: `${item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`
            }
        }).then(() => {
            setLoading(false);
            getSectionList();
        }).catch((error) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
        })
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this item.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setDelLoading(true);
                apiRequest({
                    method: 'DELETE',
                    url: `/section/${item.id}`
                }).then(() => {
                    setDelLoading(false);
                    getSectionList();
                }).catch((error) => {
                    setDelLoading(false); // Set loading state to false on error
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error.message,
                        width: 400
                    });
                });
            }
        });
    }

    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{item.title}</td>
            <td>{item.status}</td>
            <td>{item.updated_by}</td>

            <td>
                <Link to={`/sections/edit/${item.id}`}><i className="icon-pencil"></i></Link>
                {
                    delLoading ? (
                        <span className='indicator-progress'>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ) : (
                        <button type="button" onClick={handleDelete}>
                            <i className="icon-trash"></i>
                        </button>
                    )
                }
            </td>
            {/* <td>
                <Switch checked={item.status === 'ACTIVE' ? true : false} onClick={handleCheckedClick} loading={loading} />
            </td> */}
        </tr>
    )
}
