import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { useFormik } from 'formik';
import { pageValidation } from './../../../config/formValidationSchema/';
import { apiRequest } from '../../../api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import * as PageReducer from './PageReducer';
import JoditEditor from 'jodit-react';
import * as SectionReducer from '../Sections/SectionReducer';
import Editor from './Editor';

const initialValues = {
    title: "",
    sku: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    head_tracking_code: "",
    body_tracking_code: "",
    body: "",
    status: ""
}

const error = {
    title: "",
    sku: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    head_tracking_code: "",
    body_tracking_code: "",
    body: "",
    status: ""
}

const EditPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [values, setFormData] = useState({ ...initialValues });
    const [errors, setError] = useState({ ...error });
    const pathname = window.location.pathname.split('/');
    const pageSku = pathname[pathname.length - 1];
    const editor = useRef(null);
    const [content, setContent] = useState([]);
    const [section, setSection] = useState('');
    const { sectionList } = useSelector(({ section }) => section);
    const [sliderList, setSliderList] = useState([]);
    // const [editorCount, setEditorCount] = useState([])

    const config = {
        placeholder: "Start Typing..."
    }

    useEffect(() => {
        let totalContent = content + section;
        setContent(totalContent)
    }, [section])

    useEffect(() => {
        setContent(values.body ? JSON.parse(values.body) : [])
    }, [values])

    const getSectionLists = () => {
        apiRequest({
            method: "GET",
            url: "section"
        }).then(resp => {
            const filterData = resp.data.filter(item => item.status === 'ACTIVE');
            dispatch(SectionReducer.actions.sectionList(filterData));
        }).catch(error => {
            dispatch(SectionReducer.actions.sectionList([]));
        });
    };
    const getSliderLists = () => {
        apiRequest({
            method: "GET",
            url: "slider"
        }).then(resp => {
            setSliderList(resp.data);
        }).catch(error => {
            console.log(error);
        });
    };
    useEffect(() => {
        getSectionLists();
        getSliderLists();
    }, []);

    const getPages = () => {
        apiRequest({
            method: "GET",
            url: "page"
        }).then(resp => {
            dispatch(PageReducer.actions.pageList(resp.data));
        }).catch(error => {
            console.log("get all news list error", error);
        });
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setFormData({
            ...values,
            [name]: value
        })

        if (name == 'title') {
            setError({
                ...errors,
                title: ''
            })
        }

        if (name == 'sku') {
            setError({
                ...errors,
                sku: ''
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let flag = true;
        if (!values.title) {
            setError({
                ...errors,
                title: "Title is required"
            })
            flag = false;
        }

        if (!values.sku) {
            setError({
                ...errors,
                sku: "SKU is required"
            })
            flag = false;
        }

        if (flag) {
            setLoading(true);
            apiRequest({
                method: 'PUT',
                url: `/page/${values.id}`,
                data: {
                    ...values,
                    body: JSON.stringify(content)
                }
            }).then(() => {
                setLoading(false);
                navigate('/pages')
                getPages();
            }).catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                    width: 400
                });
            })
        }
    }


    useEffect(() => {
        apiRequest({
            method: "GET",
            url: `page/${pageSku}`
        }).then(resp => {
            setFormData(resp.data);
        }).catch(error => {
            console.log("get all news list error", error);
        });
    }, [])

    const handleChangeEditor = (newContent, index) => {
        const temp = [...content];
        temp[index]['content'] = newContent;

        setContent(temp);
    }

    const handleChangeDropdown = (event, index, slider='') => {
        const temp = [...content];
        if(event.target.name === 'staticSection'){
            temp[index]['type'] = 'static';
            temp[index]['contentType'] = event.target.value;
            let cssVal = sectionList.find(item => item.id === event.target.value);
            temp[index]['contentCSS'] = cssVal.css;
        }
        if(event.target.name === 'dynamicSection'){
            temp[index]['type'] = 'dynamic';
            temp[index]['contentType'] = event.target.value;
        }

        // temp[index]['type'] = 'dynamic';
        // temp[index]['contentType'] = contentType;
        temp[index]['content'] = '';
        if(slider){
            temp[index]['sliderid'] = slider;
        }

        setContent(temp);
    }

    const handleChangeInput = (index, key, value) => {
        const temp = [...content];
        temp[index][key] = value;
        setContent(temp);
    }

    const deleteBackgroundImage = (index, key) => {
        const temp = [...content];
        temp[index][key] = '';
        setContent(temp);
    }

    const handleRemoveEditor = (index) => {
        const temp = [...content];
        temp.splice(index, 1);

        setContent(temp);
    }

    return (
        <>
            {/* <div className="container-fluid" style={{marginTop: "-20px", marginBottom: "50px"}}> */}
            <div className="container">
                <div className="row event-list-wrapper">
                    <div className="col-12 col-sm-12">
                        <p className='text-danger fw-bold fs-4'>Edit Pages</p>
                        <form className="" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Page Title</label>
                                <input type="text" className="form-control" id="title" name="title" placeholder="Page Title" value={values.title} onChange={handleChange} />
                                <p className='text-danger'>{errors.title}</p>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="sku" className="form-label">Page SKU</label>
                                <input type="text" className="form-control" id="sku" name="sku" placeholder="Page SKU" value={values.sku} onChange={handleChange} />
                                <p className='text-danger'>{errors.sku}</p>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="meta_title" className="form-label">Page Meta title</label>
                                <input type="text" className="form-control" id="meta_title" name="meta_title" placeholder="Page Meta title" value={values.meta_title} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="meta_keywords" className="form-label">Page Meta Keywords</label>
                                <input type="text" className="form-control" id="meta_keywords" name="meta_keywords" placeholder="Page Meta Keywords" value={values.meta_keywords} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="meta_description" className="form-label">Page Meta Description</label>
                                <input type="text" className="form-control" id="meta_description" name="meta_description" placeholder="Page Meta Description" value={values.meta_description} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="head_tracking_code" className="form-label">Page Head Tracking Code</label>
                                <input type="text" className="form-control" id="head_tracking_code" name="head_tracking_code" placeholder="Page Head Tracking Code" value={values.head_tracking_code} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="body_tracking_code" className="form-label">Page Body Tracking Code</label>
                                <input type="text" className="form-control" id="body_tracking_code" name="body_tracking_code" placeholder="Page Body Tracking Code" value={values.body_tracking_code} onChange={handleChange} />
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="active" className="form-label">Status(Active/Inactive): </label>
                                <div className='d-flex'>
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" id="active" name="status" value="ACTIVE" onChange={handleChange} checked={values.status === 'ACTIVE'} />Active
                                    </div>
                                    <div className="form-check mx-3">
                                        <input type="radio" className="form-check-input" id="inactive" name="status" value="INACTIVE" onChange={handleChange} checked={values.status === 'INACTIVE'} />Inactive
                                    </div>
                                </div>
                            </div> */}
                            <div className="mb-3">
                                <div className='my-2'>
                                    <label htmlFor="body" className="form-label">Page Body</label>
                                </div>
                                {
                                    content.map((item, index) => {
                                        return (
                                            <Editor
                                                item={item}
                                                index={index}
                                                key={item.id}
                                                sectionList={sectionList}
                                                handleChangeEditor={handleChangeEditor}
                                                handleRemoveEditor={handleRemoveEditor}
                                                handleChangeDropdown={handleChangeDropdown}
                                                handleChangeInput={handleChangeInput}
                                                deleteBackgroundImage={deleteBackgroundImage}
                                                sliderList={sliderList}
                                                pageTitle={values.title}
                                            />
                                        )
                                    })
                                }
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-6">
                                    <button type='button' className='btn btn-success p-0 px-2 py-1 mx-1' onClick={() => {
                                        const newEditor = { id: Date.now(), type: '', contentType: '', content: '' };
                                        setContent([...content, newEditor])
                                    }}>
                                        Add New Sections
                                    </button>
                                </div>
                                <div className="col-6 col-sm-6 justify-content-end d-flex">
                                    <button type="submit" className='btn btn-success p-0 px-2 py-1' disabled={loading}>
                                        {
                                            loading ? (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            ) : (
                                                'Save'
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default React.memo(EditPage);