const initialState = {
    careerList: []
};


export const actionTypes = {
    careerList: '[careerList] Action'
}
  
export const CareerReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.careerList: {
            return {
                ...state,
                careerList: action.payload
            }
        }
        default: { 
            return state;
        }
    }
}

export const actions = {
    careerList: (careerList) => {
        return {
            type: actionTypes.careerList,
            payload: careerList
        }
    }
}