import React, { useState, useRef, useEffect } from 'react';
import { apiRequest } from '../../../api';
import { useNavigate } from "react-router-dom";
import JoditEditor from 'jodit-react';
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import './style.css';
import Swal from 'sweetalert2';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const CareerEdit = () => {
  const [loading, setLoader] = useState(false);
  const editor = useRef([]);
  const navigate = useNavigate();
  const pathname = window.location.pathname.split('/');
  const careerSku = pathname[pathname.length - 1];
  const [id, setId] = useState('')

  const [inputFields, setInputFields] = useState([
    {
      name: 'position_name', type: 'input', value: '', label: 'Position Name', required: true, error: ''
    },
    {
      name: 'division_name', type: 'input', value: '', label: 'Division Name', required: true, error: ''
    },
    {
      name: 'address', type: 'input', value: '', label: 'Address', required: true, error: ''
    },
    {
      name: 'salary', type: 'input', value: '', label: 'Salary', required: true, error: ''
    },
    {
      name: 'experience', type: 'input', value: '', label: 'Experience', required: true, error: ''
    },
    {
      name: 'industry_preferred', type: 'input', value: '', label: 'Industry Preferred', required: true, error: ''
    },
    {
      name: 'qualifications', type: 'input', value: '', label: 'Qualifications', required: true, error: ''
    },
    {
      name: 'competencies', type: 'input', value: '', label: 'Competencies', required: true, error: ''
    },
    {
      name: 'disclaimer', type: 'input', value: '', label: 'disclaimer', required: false, error: ''
    },
    {
      name: 'roles_responsibilities', type: 'text_editor', value: '', label: 'Roles & Responsibilites', required: true, error: ''
    },
    {
      name: 'status', type: 'radio', value: '', label: 'Status(Active/Inactive)', required: true, error: ''
    }
  ]);

  const config = {
    placeholder: "Start Typing..."
  }

  const sumbitForm = (value) => {

    setLoader(true);
    apiRequest({
      method: "PUT",
      url: `career/${id}`,
      data: value
    }).then(resp => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Success',
        text: resp.message,
        showConfirmButton: false,
        timer: 1500,
        width: 400
      })
      setLoader(false);
      navigate('/career')
    }).catch(error => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
        width: 400
      });
      setLoader(false);
    });
  };


  const onFinish = () => {
    // const value = {
    //   ...values,
    //   roles_responsibilities: editor.current.value,
    //   title: "tilte",
    //   sku: careerSku,
    //   content: "content",
    //   images: "",
    //   status
    // }
    // sumbitForm(value);
    const input = [...inputFields];
    const newObj = {};
    input.forEach((item) => {
      newObj[item.name] = item.value;
    })
    newObj['roles_responsibilities'] = editor.current.value
    sumbitForm(newObj);
  };

  const getCareer = () => {
    apiRequest({
      method: "GET",
      url: `career/${careerSku}`
    }).then(resp => {
      const fields = [...inputFields];
      const CareerData = [];
      fields.forEach((elem, index) => {
        if (elem.name === 'status') {
          CareerData.push(fields[index]);
          CareerData[index].value = resp.data[elem.name] === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE';
        } else {
          CareerData.push(fields[index]);
          CareerData[index].value = resp.data[elem.name];
        }
      });
      setInputFields(CareerData);
      setId(resp.data.id)
    }).catch(error => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
        width: 400
      });
    });
  }

  useEffect(() => {
    getCareer();
  }, [])

  const inputChangeHandler = (event, index, item) => {
    const input = { ...inputFields[index] };
    input.value = event.target.value;
    const updatedFields = [...inputFields];
    updatedFields[index] = input;
    setInputFields(updatedFields);
  };

  return (
    <div className="container-fluid">
      <div className="container enquiry-form">
        <p className='text-end'><Button type="secondary" onClick={() => navigate(-1)}>Back</Button></p>
        <p className='text-center fw-bolder fs-5' style={{ color: 'red' }}>Edit Career Form</p>
        <form className="">
          {inputFields.map((item, index) => {
            if (item.type === 'text_editor') {
              return (
                <div className="mb-3" key={`text_editor_${index}`}>
                  <label className="form-label">{item.label}</label>
                  <JoditEditor
                    ref={editor}
                    value={item.value}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                  />
                </div>
              );
            } else if (item.type === 'radio') {
              return (
                <div className="mb-3" key="radio_button">
                  {item.required && <span className='text-danger'>*</span>}
                  <label htmlFor="active" className="form-label">Status(Active/Inactive): </label>
                  <div className='d-flex'>
                    <div className="form-check">
                      <input type="radio" className="form-check-input" id="active" name="status" value="ACTIVE" onChange={(event) => inputChangeHandler(event, index, item)} checked={item.value == 'ACTIVE'} />Active
                    </div>
                    <div className="form-check mx-3">
                      <input type="radio" className="form-check-input" id="inactive" name="status" value="INACTIVE" onChange={(event) => inputChangeHandler(event, index, item)} checked={item.value == 'INACTIVE'} />Inactive
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <div className="mb-3" key={`input_${index}`}>
                  {item.required && <span className='text-danger'>*</span>}
                  <label htmlFor={item.name} className="form-label">{item.label}</label>
                  <input type={item.type} name={item.name} className="form-control" id={item.name} placeholder={item.label} value={item.value} onChange={(event) => inputChangeHandler(event, index, item)}
                  />
                </div>
              )
            }
          })}
          <div className="mb-3">
            <button type="button" onClick={onFinish} className="btn btn-primary">
              {
                loading ? (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  'Edit Career'
                )
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CareerEdit;
