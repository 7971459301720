import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { apiRequest } from '../../../api';
import './style.css';
import { TableRow } from './TableRow';
import { useDispatch, useSelector } from 'react-redux';
import * as SliderReducer from './SliderReducer';
import Pagination from '../CommonComponent/Pagination/Pagination';

const NewsListing = () => {
    // const [newsList, setNewsList] = useState([]);
    const dispatch = useDispatch();
    const {sliderList}  = useSelector(({slider}) => slider);
    const [pageListing, setPageListing] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const pageSize = 10;

    const getFilteredList = (list, page) => {
        let lastPageItems = 0;
        lastPageItems = Math.ceil(list.length % pageSize);
        const arr = list.slice((page*pageSize), (((page)*pageSize) + pageSize));
        setCurrentPageItems(arr);
    };
    const getSlidersLists = () => {
        apiRequest({
            method: "GET",
            url: "slider"
        }).then(resp => {
            getFilteredList([...resp.data], currentPage);
            setPageListing(resp.data);
            setTotalPageCount(Math.ceil(resp.data.length/pageSize));
            dispatch(SliderReducer.actions.sliderList(resp.data));
        }).catch(error => {
            console.log(error);
        });
    };
    useEffect(() => {
        getSlidersLists();
    }, []);

    const handlePage = (page) => {
        setCurrentPage(page);
        getFilteredList([...pageListing], page);
    };
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className="list-group">
                                <div className="list-group-item active" aria-current="true">
                                    Sliders Lists
                                    <Link to="/sliders/add">Create Slider</Link>
                                </div>
                                {currentPageItems.length ? (<table className="table table-striped table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Slider Title</th>
                                            {/* <th scope="col">Slider Subtitle</th>
                                            <th scope="col">Slider Button One</th>
                                            <th scope="col">Slider Button Two</th> */}
                                            <th scope="col">Status</th>
                                            <th scope="col">Update_By</th>



                                            <th scope="col">Action</th>
                                            {/* <th scope="col">Active/Inactive</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageItems.map((item, index) => (
                                            <TableRow 
                                                item={item} 
                                                index={(currentPage*pageSize)+ index} 
                                                key={`slider_${index}`} 
                                            />
                                        ))}
                                    </tbody>
                                </table>) : null}
                                <Pagination currentPage={currentPage} totalPages={totalPageCount} pageCallBack={handlePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsListing;