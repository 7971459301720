import React, { useState, useRef } from 'react';
import './style.css';
import { useFormik } from 'formik';
import { sectionPageValidation } from '../../../config/formValidationSchema';
import { apiRequest } from '../../../api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import * as SectionReducer from './SectionReducer';
import JoditEditor from 'jodit-react';

const initialValues = {
    title: "",
    css: "",
    body: "",
    status: ""
}

const PagesAdd = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const editor = useRef(null);
    const [content, setContent] = useState('');

    const config = {
        placeholder: "Start Typing..."
    }

    const { values, errors, handleSubmit, handleChange, handleBlur } = useFormik({
        initialValues,
        validationSchema: sectionPageValidation,
        onSubmit: (values) => {
            setLoading(true);
            apiRequest({
                method: 'POST',
                url: `/section`,
                data: {
                    ...values,
                    body: editor.current.value
                }
            }).then(() => {
                setLoading(false);
                navigate('/sections')
                getSectionLists();
            }).catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                    width: 400
                });
            })
        }
    })

    const getSectionLists = () => {
        apiRequest({
            method: "GET",
            url: "section"
        }).then(resp => {
            dispatch(SectionReducer.actions.pageList(resp.data));
        }).catch(error => {
            dispatch(SectionReducer.actions.pageList([]));
        });
    };

    return (
        <>
            {/* <div className="container-fluid" style={{marginTop: "-20px", marginBottom: "50px"}}> */}
            <div className="container">
                <div className="row event-list-wrapper">
                    <div className="col-12 col-sm-12">
                        <p className='text-danger fw-bold fs-4'>Add Section</p>
                        <form className="" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Section Title</label>
                                <input type="text" className="form-control" id="title" name="title" placeholder="Menu Title" value={values.title} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <p className='text-danger'>{errors.title}</p>
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="sku" className="form-label">Menu SKU</label>
                                <input type="text" className="form-control" id="sku" name="sku" placeholder="Menu SKU" value={values.sku} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <p className='text-danger'>{errors.sku}</p>
                            </div> */}
                            <div className="mb-3">
                                <label htmlFor="css" className="form-label">Section CSS</label>
                                <textarea type="text" className="form-control" id="css" name="css" placeholder="Section CSS" rows="10" value={values.css} onChange={handleChange} onBlur={handleBlur} autoComplete = "off" ></textarea>
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="body" className="form-label">Section CSS</label>
                                <input type="text" className="form-control" id="css" name="css" placeholder="Section CSS" value={values.css} onChange={handleChange} onBlur={handleBlur} autoComplete = "off" />
                            </div> */}
                            <div className="mb-3">
                                <label htmlFor="body" className="form-label">Section Body</label>
                                <JoditEditor
                                    ref={editor}
                                    value={values.body}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newContent => {}}
                                />
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="body" className="form-label">Menu Body</label>
                                <textarea type="text" className="form-control" id="body" name="body" placeholder="Menu Body" rows="10" value={content} autoComplete = "off" ></textarea>
                            </div> */}
                            {/* <div className="mb-3">
                                <label htmlFor="body" className="form-label">Menu Body</label>
                                <input type="text" className="form-control" id="body" name="body" placeholder="Menu Body" value={values.body} onChange={handleChange} onBlur={handleBlur} autoComplete = "off" />
                            </div> */}
                            <div className="mb-3">
                                <label htmlFor="active" className="form-label">Status(Active/Inactive): </label>
                                <div className='d-flex'>
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" id="active" name="status" value="ACTIVE" onChange={handleChange} onBlur={handleBlur} />Active
                                    </div>
                                    <div className="form-check mx-3">
                                        <input type="radio" className="form-check-input" id="inactive" name="status" value="INACTIVE" onChange={handleChange} onBlur={handleBlur} />Inactive
                                    </div>
                                </div>
                                <p className='text-danger'>{errors.status}</p>
                            </div>
                            <div className="row">
                                <div className="col-9 col-sm-9">
                                    <button type="submit" className='btn btn-success p-0 px-2 py-1' disabled={loading}>
                                        {
                                            loading ? (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            ) : (
                                                'Save'
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default PagesAdd;