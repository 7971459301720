import React, { useState } from 'react';
import { apiRequest } from '../../../api';
import { useNavigate } from 'react-router-dom';
import './style.css';
import Swal from 'sweetalert2';

const MediaAdd = () => {
    const [path, setPath] = useState('');
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [imageTitle, setImageTitle] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();


    const imageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]))
            setPath(e.target.files[0]);
            setError('');
        }
    }

    const handleSave = () => {
        if(path) {
            setLoading(true);
            apiRequest({
                method: "POST",
                url: 'image',
                data: { image: path, title: imageTitle },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(resp => {
                setLoading(false);
                navigate('/mediaupload')
            }).catch(error => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                    width: 400
                });
            });
        } else {
            setError('Please upload image')
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className="list-group">
                                <span className='fs-5 fw-bold text-danger'>Add Media</span>

                                <div className="mb-3">
                                    <label className="form-label">Media Title</label>
                                    <input type="text" className="form-control" name="title" placeholder="Media Title" value={imageTitle} onChange={(e) => setImageTitle(e.target.value)} autoComplete="off" />
                                </div>
                                <div className='my-2'>
                                    <label htmlFor="mobile_image" className="form-label">Upload Image</label>
                                    {image && 
                                        <div className='my-3'>
                                            <img src={image} alt='media' width={'150px'} height={'150px'} />
                                        </div>
                                    }
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="upload_img"
                                        name="upload_img"
                                        placeholder="Upload Image"
                                        onChange={(e) => imageChange(e)}
                                        autoComplete="off"
                                    />
                                </div>
                                <p className='text-danger my-1'>{error}</p>
                                <div className="row">
                                    <div className="col-9 col-sm-9">
                                        <button type="button" className='btn btn-success p-0 px-2 py-1' disabled={loading} onClick={handleSave}>
                                            {
                                                loading ? (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                ) : (
                                                    'Save'
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MediaAdd;