import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { apiRequest } from '../../../api';
import './style.css';

const CaptionEditor = ({handleChangeEditor, value, index}) => {
    const editor = useRef();
    const [editorContent, setEditorContent] = useState('');
    useEffect(() => {
        setEditorContent(value);
    }, [value]);
    return (
        <>
            <JoditEditor
                ref={editor}
                value={editorContent}
                tabIndex={1}
                onBlur={(newContent) => handleChangeEditor(newContent, index)}
                onChange={newContent => {}}
            />
        </>
    );
};

export default CaptionEditor;