import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { apiRequest } from '../../../api';
import './style.css';
import { TableRow } from './TableRow';
import { useDispatch, useSelector } from 'react-redux';
import * as MediaReducer from './MediaReducer';
import Pagination from '../CommonComponent/Pagination/Pagination';

const MediaListing = () => {
    // const [newsList, setNewsList] = useState([]);
    const dispatch = useDispatch();
    const {mediaList}  = useSelector(({media}) => media);
    const [pageListing, setPageListing] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const pageSize = 10;

    const getFilteredList = (list, page) => {
        const arr = list.slice((page*pageSize), (((page)*pageSize) + pageSize));
        setCurrentPageItems(arr);
    };

    const getMediaLists = () => {
        apiRequest({
            method: "GET",
            url: "image/getAll"
        }).then(resp => {
            const sortedList = [...resp.data].sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            getFilteredList([...sortedList], currentPage);
            setPageListing(sortedList);
            setTotalPageCount(Math.ceil(resp.data.length/pageSize));
            dispatch(MediaReducer.actions.mediaList(sortedList));
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        getMediaLists();
    }, []);

    const handlePage = (page) => {
        setCurrentPage(page);
        getFilteredList([...pageListing], page);
    };

    const handleOnBlur = (e) => {
        let list = [...pageListing];
        let filteredList = list.filter(item => {
            if(item.title){
                if(item.title.toLowerCase().includes(e.target.value.toLowerCase())){
                    return item;
                }
                return null;
            }
            return null;
        });
        if(e.target.value !== ''){
            setTotalPageCount(Math.ceil(filteredList.length/pageSize));
            setCurrentPage(0);
            getFilteredList([...filteredList], 0);
        }else{
            setTotalPageCount(Math.ceil(pageListing.length/pageSize));
            setCurrentPage(0);
            getFilteredList([...pageListing], 0);
        }
        
    };

    const handleOnKeyDown = (e) => {
        let list = [...pageListing];
        let filteredList = list.filter(item => {
            if(item.title){
                if(item.title.toLowerCase().includes(e.target.value.toLowerCase())){
                    return item;
                }
                return null;
            }
            return null;
        });
        if(e.keyCode === 13){
            if(e.target.value !== ''){
                setTotalPageCount(Math.ceil(filteredList.length/pageSize));
                setCurrentPage(0);
                getFilteredList([...filteredList], 0);
            }else{
                setTotalPageCount(Math.ceil(pageListing.length/pageSize));
                setCurrentPage(0);
                getFilteredList([...pageListing], 0);
            }
        }
    };

    // const formateDate = (eventdate) => {
    //     const date = new Date(eventdate);
    // };
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className="list-group">
                                <div className="list-group-item active" aria-current="true">
                                    Media Lists
                                    <Link to="/mediaupload/add">Add Media</Link>
                                </div>
                                <div className="d-flex justify-content-end mb-4 mt-4">
                                    <div className="tableSearch">
                                        <input
                                            type="text" 
                                            placeholder="Search..." 
                                            className="form-control" 
                                            onBlur={handleOnBlur}
                                            onKeyDown={handleOnKeyDown}
                                        />
                                    </div>
                                </div>
                                {currentPageItems.length ? (<table className="table table-striped table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Media</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageItems.map((item, index) => (
                                            <TableRow 
                                                item={item} 
                                                index={(currentPage*pageSize) + index} 
                                                key={`media_${index}`} 
                                            />
                                        ))}
                                    </tbody>
                                </table>) : null}
                                {
                                    currentPageItems.length == 0 && (
                                        <div className='text-center mb-5'>
                                            -- no record found --
                                        </div>
                                    )
                                }
                                <Pagination currentPage={currentPage} totalPages={totalPageCount} pageCallBack={handlePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MediaListing;