import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { apiRequest } from "../../../api";
import constant from "../../../config/config";
import "./style.css";
import Swal from "sweetalert2";
import JoditEditor from "jodit-react";

const NewsEdit = () => {
  const [inputFields, setInputFields] = useState([
    {
      name: "title",
      value: "",
      label: "Title",
      type: "input",
    },
    {
      name: "sku",
      value: "",
      label: "SKU",
      type: "input",
    },
    {
      name: "news_date",
      value: "",
      label: "News Time",
      type: "date",
    },
    {
      name: "status",
      value: 1,
      label: "Status ( Active - 1, Inactive - 0 )",
      type: "input",
    },
    {
      name: "feature_new",
      value: false,
      label: "Featured News",
      type: "checkbox",
    },
    {
      name: "thumbnail_image",
      value: "",
      label: "Thumb Image",
      type: "file",
    },
    {
      name: "banner_image",
      value: "",
      label: "Banner Image",
      type: "file",
    },
    {
      name: "body",
      value: "",
      label: "Body",
      type: "editor",
      ref: useRef(null),
    },
  ]);

  const config = {
    placeholder: "Start Typing...",
  };

  const [newsId, setNewsId] = useState("");
  const navigate = useNavigate();
  const pathname = window.location.pathname.split("/");
  const newsSku = pathname[pathname.length - 1];
  const getNewsData = () => {
    apiRequest({
      method: "get",
      url: `news/${newsSku}`,
    })
      .then((resp) => {
        setNewsId(resp.data.id);
        const fields = [...inputFields];
        const newsData = [];
        fields.forEach((elem, index) => {
          if (elem.name === "status") {
            newsData.push(fields[index]);
            newsData[index].value = resp.data[elem.name] === "ACTIVE" ? "1" : "0";
          } else if (elem.name === "feature_new") {
            newsData.push(fields[index]);
            newsData[index].value =
              resp.data[elem.name] === "YES" ? true : false;
          } else {
            newsData.push(fields[index]);
            newsData[index].value = resp.data[elem.name];
          }
        });
        // const imgList = resp.data.image_list.map(item => ({
        //     name: 'image', value: item.url, label: 'Event Images', type: 'file'
        // }));
        // data.push({
        //     name: 'image_list', value: imgList
        // });
        setInputFields(newsData);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
          width: 400,
        });
      });
  };
  useEffect(() => {
    getNewsData();
  }, []);
  const imageChange = (event, inputIndex) => {
    const titleField = inputFields.find((item) => item.name === "title");
    apiRequest({
      method: "post",
      url: "image",
      data: { image: event.target.files[0], title: titleField.value },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((resp) => {
        const input = { ...inputFields[inputIndex] };
        input.value =  resp.data.full_path;
        const updatedFields = [...inputFields];
        updatedFields[inputIndex] = input;
        setInputFields(updatedFields);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteImage = (inputIndex) => {
    const input = { ...inputFields[inputIndex] };
    input.value = "";
    const updatedFields = [...inputFields];
    updatedFields[inputIndex] = input;
    setInputFields(updatedFields);
  };
  const inputChangeHandler = (event, index, item) => {
    const input = { ...inputFields[index] };
    input.value = event.target.value;
    const updatedFields = [...inputFields];
    updatedFields[index] = input;
    setInputFields(updatedFields);

    if (input.name === 'title') {
      const skuField = updatedFields.find(item => item.name === 'sku');
      skuField.value = event.target.value.replaceAll(' ', '-');
  }

  setInputFields(updatedFields);
  
  };
  const checkboxChangeHandler = (event, index, item) => {
    const input = { ...inputFields[index] };
    input.value = event.target.checked;
    const updatedFields = [...inputFields];
    updatedFields[index] = input;
    setInputFields(updatedFields);
  };
  const focusHandler = (event, index) => {
    if (!event.target.value && event.target.name === "sku") {
      const input = { ...inputFields[index] };
      const title = { ...inputFields[0] };
      input.value = title.value.toLowerCase().replaceAll(" ", "-");
      const updatedFields = [...inputFields];
      updatedFields[index] = input;
      setInputFields(updatedFields);
    }
  };
  const submitForm = () => {
    const formData = {};
    inputFields.forEach((item) => {
      if (item.name === "status") {
        formData[item.name] = item.value == "1" ? "ACTIVE" : "INACTIVE";
      } else if (item.type === "editor") {
        const editorContent = item.ref.current.value;
        formData[item.name] = editorContent
          .replaceAll("\r\n", "")
          .replaceAll("\n", "");
      } else if (item.name === "feature_new") {
        formData[item.name] = item.value === true ? "YES" : "NO";
      } else {
        formData[item.name] = item.value;
      }
    });
    apiRequest({
      method: "put",
      url: `news/${newsId}`,
      data: formData,
    })
      .then((resp) => {
        navigate("/news");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
          width: 400,
        });
      });
  };
  const setEditorRef = (editor, item, index) => {
    const fields = [...inputFields];
    const editorInput = fields[index];
    editorInput.ref.current = editor;
    fields[index] = editorInput;
    setInputFields(fields);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row height-vh event-list-wrapper">
            <div className="col-12 col-sm-12">
              <div className="list-group">
                <div className="list-group-item active" aria-current="true">
                  Add News
                  <Link to="/news/add">Create News</Link>
                </div>
                <form className="">
                  {inputFields.map((item, index) => {
                    if (item.type === "editor") {
                      return (
                        <div className="mb-3" key={`input_${index}`}>
                          <label htmlFor={item.name} className="form-label">
                            {item.label}
                          </label>
                          <JoditEditor
                            ref={item.ref}
                            value={item.value}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={(newContent) => {}}
                          />
                        </div>
                      );
                    } else if (item.type === "file") {
                      if (item.value) {
                        return (
                          <div
                            className="mb-3 img-thumb"
                            key={`input_${index}`}
                          >
                            <img src={`${constant.imageUrl}${item.value}`} className="img-fluid" />
                            <button
                              type="button"
                              onClick={() => deleteImage(index)}
                            >
                              <i className="icon-close"></i>
                            </button>
                          </div>
                        );
                      }
                      return (
                        <div className="mb-3" key={`input_${index}`}>
                          <label className="form-label">{item.label}</label>
                          <input
                            type={item.type}
                            className="form-control"
                            onChange={(event) => imageChange(event, index)}
                          />
                        </div>
                      );
                    } else if (item.type === "checkbox") {
                      return (
                        <div className="mb-3" key={`input_${index}`}>
                          <label htmlFor={item.name} className="form-label">
                            {item.label}
                          </label>
                          <input
                            type={item.type}
                            name={item.name}
                            className="form-check-input"
                            id={item.name}
                            placeholder={item.label}
                            checked={item.value}
                            onChange={(event) =>
                              checkboxChangeHandler(event, index, item)
                            }
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div className="mb-3" key={`input_${index}`}>
                          <label htmlFor={item.name} className="form-label">
                            {item.label}
                          </label>
                          <input
                            type={item.type}
                            name={item.name}
                            className="form-control"
                            id={item.name}
                            placeholder={item.label}
                            value={item.value}
                            onChange={(event) =>
                              inputChangeHandler(event, index, item)
                            }
                            onFocus={(event) => focusHandler(event, index)}
                          />
                        </div>
                      );
                    }
                  })}
                  <div className="mb-3">
                    <button
                      type="button"
                      onClick={submitForm}
                      className="btn btn-primary"
                    >
                      Add News
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsEdit;
