import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { apiRequest } from '../../../api';
import './style.css';
import { TableRow } from './TableRow';
import { useDispatch, useSelector } from 'react-redux';
import * as MenuReducer from './MenuReducer';

const MenuListing = () => {
    const dispatch = useDispatch();
    const { menuList } = useSelector(({ menu }) => menu);

    const getMenuLists = () => {
        apiRequest({
            method: "GET",
            url: "menu"
        }).then(resp => {
            dispatch(MenuReducer.actions.menuList(resp.data));
        }).catch(error => {
            dispatch(MenuReducer.actions.menuList([]));
        });
    };
    useEffect(() => {
        getMenuLists();
    }, []);



    // const formateDate = (eventdate) => {
    //     const date = new Date(eventdate);
    // };
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className="list-group">
                                <div className="list-group-item active" aria-current="true">
                                    Menu Lists
                                    {/*<Link to="/menus/add">Create Menu</Link>*/}
                                </div>
                                <table className="table table-striped table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Menu Title</th>
                                            <th scope="col">SKU</th>
                                            <th scope='col'>Updated_By</th>
                                            <th scope="col">Action</th>
                                            {/* <th scope="col">Active/Inactive</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {menuList.map((item, index) => (
                                            <TableRow item={item} index={index} key={`event_${index}`} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {
                                menuList.length == 0 && (
                                    <div className='text-center'>
                                        -- no record found --
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuListing;