import { combineReducers } from 'redux';
import * as AuthReducer from "../../pages/Auth/AuthReducer";
import * as EventReducer from "../../pages/AfterAuth/Event/EventReducer";
import * as NewsReducer from "../../pages/AfterAuth/News/NewsReducer";
import * as DealerReducer from "../../pages/AfterAuth/Dealers/DealerReducer";
import * as PageReducer from "../../pages/AfterAuth/Pages/PageReducer";
import * as MenuReducer from "../../pages/AfterAuth/Menu/MenuReducer";
import * as SectionReducer from "../../pages/AfterAuth/Sections/SectionReducer";
import * as EnquiryReducer from "../../pages/AfterAuth/EnquireNow/EnquiryReducer";
import * as SliderReducer from "../../pages/AfterAuth/Slider/SliderReducer";
import * as MediaReducer from "../../pages/AfterAuth/Media/MediaReducer";
import * as CareerReducer from "../../pages/AfterAuth/Career/CareerReducer";
import * as UserReducer from "../../pages/AfterAuth/User/UserReducer"
// root reducer of app
export const rootReducer = combineReducers({
  auth: AuthReducer.AuthReducer,
  event: EventReducer.EventReducer,
  news: NewsReducer.NewsReducer,
  dealer: DealerReducer.DealerReducer,
  page: PageReducer.PageReducer,
  menu: MenuReducer.MenuReducer,
  section: SectionReducer.SectionReducer,
  enquiry: EnquiryReducer.EnquiryReducer,
  slider: SliderReducer.SliderReducer,
  media: MediaReducer.MediaReducer,
  career: CareerReducer.CareerReducer,
  user: UserReducer.UserReducer
})


// store configuration for reducers
// export default function configureStore() {
//   let store = createStore(rootReducer, compose(
//     applyMiddleware(thunk),
//     window.__REDUX_DEVTOOLS_EXTENSION__
//       ? window.__REDUX_DEVTOOLS_EXTENSION__()
//       : (f) => f,
//   ),); // thunk middleware for async tasks
//   return store;
// }
