import React, { useRef, useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import { apiRequest } from "../../../api";
import constant from "../../../config/config";

const Editor = ({
  item,
  sectionList,
  index,
  handleChangeEditor,
  handleChangeDropdown,
  handleRemoveEditor,
  sliderList,
  handleChangeInput,
  deleteBackgroundImage,
  pageTitle,
}) => {
  const [section, setSection] = useState("");
  const [handleDynamicData, setDynamicData] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const editor = useRef();
  const updateEditorContent = (section) => {
    const data = sectionList.find((item) => item.id === section);
    if (data) {
      setEditorContent(item.content + data.body);
    }
  };

  const uploadImage = (e) => {
    apiRequest({
      method: "POST",
      url: "image",
      data: { image: e.target.files[0], title: pageTitle },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((resp) => {
        const img = resp.data.full_path;
        handleChangeInput(index, e.target.name, img);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setEditorContent(item.content);
  }, [item]);

  const sliderSelection = [
    // 'SLIDER_WITH_SINGLE_IMAGE',
    "SLIDER_WITH_MULTIPLE_IMAGE",
    "SLIDER_WITH_TOP_CAPTION",
    "HOME_SLIDER",
    "SLIDER_WITH_LEFT_CAPTION",
    "SLIDER_WITH_RIGHT_CAPTION",
  ];
  const sliderLeftRightCaption = [
    "SLIDER_WITH_LEFT_CAPTION",
    "SLIDER_WITH_RIGHT_CAPTION",
  ];
  let sectionType = [
    {
      label: "Home Slider",
      value: "HOME_SLIDER",
    },
    // {
    //     label: 'Slider with single image', value: 'SLIDER_WITH_SINGLE_IMAGE'
    // },
    {
      label: "Slider with multiple image",
      value: "SLIDER_WITH_MULTIPLE_IMAGE",
    },
    {
      label: "Slider with top caption",
      value: "SLIDER_WITH_TOP_CAPTION",
    },
    {
      label: "Slider with left caption",
      value: "SLIDER_WITH_LEFT_CAPTION",
    },
    {
      label: "Slider with right caption",
      value: "SLIDER_WITH_RIGHT_CAPTION",
    },
    {
      label: "Test drive form",
      value: "TEST_DRIVER_FORM",
    },
    {
      label: "News",
      value: "NEWS",
    },
    {
      label: "News Letter",
      value: "NEWSLETTER",
    },
    {
      label: "Event",
      value: "EVENT",
    },
    {
      label: "Dealers List",
      value: "DEALERLIST",
    },
    {
      label: "Contact Us Form",
      value: "CONTACTUSFORM",
    },
    {
      label: "Career",
      value: "CAREER_LIST",
    },
    {
      label: "bydsealhomeimage",
      value: "BYDSEALHOMESLIDER_PAGE",
    },
    {
      label: "BYDSEAL CAR SLIDER",
      value: "BYDSEAL_SLIDER",
    },
    {
      label: "BYDATTO3 CAR SLIDER",
      value: "BYDATTO_SLIDER",
    },
    {
      label: "bydatto3homeimage",
      value: "BYDATTOHOMESLIDER_PAGE",
    },
    {
      label: "bydemax7homeimage",
      value: "BYDEMAXHOMESLIDER_PAGE",
    },
    {
      label: "BYDEMAX CAR SLIDER",
      value: "BYDEMAX_SLIDER",
    },
  ];

  return (
    <>
      <div className="d-flex my-2">
        <div className="col-xl-2 fv-row fv-plugins-icon-container">
          <select
            className="form-select form-select-solid"
            data-control="select2"
            data-hide-search="true"
            name="staticSection"
            tabindex="-1"
            aria-hidden="false"
            value={item.contentType}
            onChange={(e) => {
              // setSection(e.target.value);
              updateEditorContent(e.target.value);
              handleChangeDropdown(e, index);
            }}
          >
            <option value="">Select Static Section</option>
            {sectionList.map((item, index) => {
              return (
                <option value={item.id} key={index}>
                  {item.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-xl-2 fv-row fv-plugins-icon-container mx-1">
          <input
            type="text"
            className="form-control"
            id="hashId"
            name="hashId"
            placeholder="Url Hash Id"
            value={item.hashId}
            onChange={(e) =>
              handleChangeInput(index, e.target.name, e.target.value)
            }
            autoComplete="off"
          />
        </div>
        <div className="col-xl-2 fv-row fv-plugins-icon-container mx-1">
          <input
            type="text"
            className="form-control"
            id="sectionTitle"
            name="sectionTitle"
            placeholder="Dynamic Section Title"
            value={item.sectionTitle}
            onChange={(e) =>
              handleChangeInput(index, e.target.name, e.target.value)
            }
            autoComplete="off"
          />
        </div>

        {/* Section type */}
        <div className="col-xl-2 fv-row fv-plugins-icon-container mx-1">
          <select
            className="form-select form-select-solid"
            data-control="select2"
            data-hide-search="true"
            name="dynamicSection"
            tabindex="-1"
            aria-hidden="false"
            value={item.type === "dynamic" ? item.contentType : ""}
            onChange={(e) => {
              // setSection('');
              handleChangeDropdown(e, index);
              setDynamicData(e.target.value);
            }}
          >
            <option value="">Select Dynamic Section </option>
            {sectionType.map((type, index) => {
              return (
                <option value={type.value} key={index}>
                  {type.label}
                </option>
              );
            })}
          </select>
        </div>

        {/** Slider List */}
        {(sliderSelection.includes(handleDynamicData) || item.sliderid) && (
          <div className="col-xl-2 fv-row fv-plugins-icon-container mx-1">
            <select
              className="form-select form-select-solid"
              data-control="select2"
              data-hide-search="true"
              name="sliderId"
              tabindex="-1"
              aria-hidden="false"
              value={item.sliderid}
              onChange={(e) => {
                handleChangeDropdown(e, index, e.target.value);
              }}
            >
              <option value="">Select Slider</option>
              {sliderList.map((type, index) => {
                return (
                  <option value={type.id} key={index}>
                    {type.title}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        <div>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleRemoveEditor(index)}
          >
            <span className="fw-bold fs-7">-</span>
          </button>
        </div>
      </div>
      {/* {item.contentType === 'TEST_DRIVER_FORM' && (<div className="my-4"> */}
      {item.backgroundImage ? (
        <div className="mb-3 img-thumb">
          <img src={item.backgroundImage} className="img-fluid" />
          <button
            type="button"
            onClick={() => deleteBackgroundImage(index, "backgroundImage")}
          >
            <i className="icon-close"></i>
          </button>
        </div>
      ) : (
        <div className="mb-3">
          <label className="form-label">Background Image</label>
          <input
            type="file"
            className="form-control"
            name="backgroundImage"
            value=""
            onChange={(e) => uploadImage(e)}
          />
        </div>
      )}
      {/* </div>)} */}
      {item.contentType === "TEST_DRIVER_FORM" && (
        <div className="my-4">
          <div className="mb-3">
            <label className="form-label">Model Index</label>
            <input
              type="text"
              className="form-control"
              id="modelIndex"
              name="modelIndex"
              placeholder="Model Index"
              value={item.modelIndex}
              onChange={(e) =>
                handleChangeInput(index, e.target.name, e.target.value)
              }
              autoComplete="off"
            />
          </div>
        </div>
      )}
      {(item.type == "static" ||
        item.type == "" ||
        sliderLeftRightCaption.includes(item.contentType)) && (
        <div className="my-4">
          <JoditEditor
            ref={editor}
            value={editorContent}
            // config={config}
            tabIndex={1} // tabIndex of textarea
            // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(newContent) => handleChangeEditor(newContent, index)}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(Editor);
