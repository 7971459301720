import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { apiRequest } from '../../../api';
import './style.css';
import { TableRow } from './TableRow';
import { useDispatch, useSelector } from 'react-redux';
import * as SectionReducer from './SectionReducer';
import Pagination from '../CommonComponent/Pagination/Pagination';

const MenuListing = () => {
    const dispatch = useDispatch();
    const { sectionList } = useSelector(({ section }) => section);
    const [pageListing, setPageListing] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const pageSize = 10;

    const getFilteredList = (list, page) => {
        let lastPageItems = 0;
        lastPageItems = Math.ceil(list.length % pageSize);
        const arr = list.slice((page*pageSize), (((page)*pageSize) + pageSize));
        setCurrentPageItems(arr);
    };
    const getSectionLists = () => {
        apiRequest({
            method: "GET",
            url: "section"
        }).then(resp => {
            getFilteredList([...resp.data], currentPage);
            setPageListing(resp.data);
            setTotalPageCount(Math.ceil(resp.data.length/pageSize));
            dispatch(SectionReducer.actions.sectionList(resp.data));
        }).catch(error => {
            dispatch(SectionReducer.actions.sectionList([]));
        });
    };
    const handlePage = (page) => {
        setCurrentPage(page);
        getFilteredList([...pageListing], page);
    };
    const handleOnBlur = (e) => {
        let list = [...pageListing];
        if(e.target.value !== ''){
            let filteredList = list.filter(item => {
                if(item.title){
                    if(item.title.toLowerCase().includes(e.target.value.toLowerCase())){
                        return item;
                    }
                    return null;
                }
                return null;
            });
            setTotalPageCount(Math.ceil(filteredList.length/pageSize));
            setCurrentPage(0);
            getFilteredList([...filteredList], 0);
        }else{
            setTotalPageCount(Math.ceil(pageListing.length/pageSize));
            setCurrentPage(0);
            getFilteredList([...pageListing], 0);
        }
    };
    const handleOnKeyDown = (e) => {
        let list = [...pageListing];
        if(e.keyCode === 13){
            if(e.target.value !== ''){
                let filteredList = list.filter(item => {
                    if(item.title){
                        if(item.title.toLowerCase().includes(e.target.value.toLowerCase())){
                            return item;
                        }
                        return null;
                    }
                    return null;
                });
                setTotalPageCount(Math.ceil(filteredList.length/pageSize));
                setCurrentPage(0);
                getFilteredList([...filteredList], 0);
            }else{
                setTotalPageCount(Math.ceil(pageListing.length/pageSize));
                setCurrentPage(0);
                getFilteredList([...pageListing], 0);
            }
        }
    };
    useEffect(() => {
        getSectionLists();
    }, []);



    // const formateDate = (eventdate) => {
    //     const date = new Date(eventdate);
    // };
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className="list-group">
                                <div className="list-group-item active" aria-current="true">
                                    Section Lists
                                    <Link to="/sections/add">Create Sections</Link>
                                </div>
                                <div className="d-flex justify-content-end mb-4 mt-4">
                                    <div className="tableSearch">
                                        <input
                                            type="text" 
                                            placeholder="Search..." 
                                            className="form-control" 
                                            onBlur={handleOnBlur}
                                            onKeyDown={handleOnKeyDown}
                                        />
                                    </div>
                                </div>
                                <table className="table table-striped table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Section Title</th>
                                            <th scope="col">status</th>
                                            <th scope='col'>Updated_By</th>

                                            <th scope="col">Action</th>
                                            {/* <th scope="col">Active/Inactive</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageItems.length > 0 && currentPageItems.map((item, index) => (
                                            <TableRow item={item} index={index} key={`section__${index}`} />
                                        ))}
                                    </tbody>
                                </table>
                                {
                                    currentPageItems.length === 0 && (
                                        <div className='text-center'>
                                            -- no record found --
                                        </div>
                                    )
                                }
                                <Pagination currentPage={currentPage} totalPages={totalPageCount} pageCallBack={handlePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuListing;