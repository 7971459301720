import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2';
import { apiRequest } from '../../../api';
// import jwt_decode from "jwt-decode";
import validator from "validator";
import { useNavigate  } from "react-router-dom";

const ChangePassword = () => {
    const [email, setEmail] = useState({value: '', error: ''});
    const [oldPwd, setOldPwd] = useState({ value: '', error: '' });
    const [newPwd, setNewPwd] = useState({ value: '', error: '' });
    const [confirmNewPwd, setConfirmNewPwd] = useState({ value: '', error: '' });
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const changePwdAction = () => {
        setLoader(true);
        apiRequest({
            method: "put",
            url: "user/password",
            data: {
                email: email.value,
                old_password: oldPwd.value,
                password: newPwd.value,
                confirm_password: confirmNewPwd.value
            }
        }).then(resp => {
            setLoader(false);
            localStorage.setItem('accesstoken', resp.data.token);
            Swal.fire({
                position: 'center',
                icon: 'success',
                text: 'password changed successfully',
                showConfirmButton: false,
                timer: 1500,
                width: 400
            })
        }).catch(error => {
            setLoader(false);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
        });
    };

    const handleInputChange = (e) => {
        if(e.target.name === 'email') {
            if(validator.isEmail(e.target.value)) {
                setEmail({
                    ...email,
                    value: e.target.value,
                    error: ''
                })
            } else {
                setEmail({
                    ...email,
                    value: e.target.value,
                    error: 'email format is not valid'
                })
            }
        }

        if (e.target.name === 'old-pwd') {
            setOldPwd({
                ...oldPwd,
                value: e.target.value,
                error: ''
            })
        }

        if (e.target.name === 'new-pwd') {
            setNewPwd({
                ...newPwd,
                value: e.target.value,
                error: ''
            })
        }

        if (e.target.name === 'confirm-new-pwd') {
            setConfirmNewPwd({
                ...confirmNewPwd,
                value: e.target.value,
                error: ''
            })
        }
    }

    const handleChangePassword = () => {
        let flag = true;
        if(!email.value) {
            setEmail({
                ...email,
                error: 'email can not be blank'
            })
            flag = false;
        }
        if(email.error) {
            flag = false;
        }
        if (!oldPwd.value) {
            setOldPwd({
                ...oldPwd,
                error: "old password can not be blank"
            })
            flag = false;
        } if (!newPwd.value) {
            setNewPwd({
                ...newPwd,
                error: "new password can not be blank"
            })
            flag = false;
        } if (!confirmNewPwd.value) {
            setConfirmNewPwd({
                ...confirmNewPwd,
                error: "confirm new password can not be blank"
            })
            flag = false;
        } else if (newPwd.value != confirmNewPwd.value) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "'new password' and 'confirm new password' are not matching",
                width: 400
            });
            flag = false;
        }

        if (flag) {
            changePwdAction();
        }

    }

    return (
        <div className="container">
            <div>
                <button className='btn btn-danger p-0 px-2 py-1' onClick={() => navigate(-1)}>
                    <i className="fa fa-arrow-left fs-9" aria-hidden="true"></i>
                    {'  '}Back
                </button>
            </div>
            <div className='text-primary fs-5 text-center fw-bold'>
                CHANGE PASSWORD
            </div>
            <div className='p-4 mt-2 m-auto' style={{ border: '1px solid #ccc', borderRadius: '5px', width: "50%" }}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        onChange={handleInputChange}
                    />
                    <span className='text-danger fs-8'>{email.error}</span>
                </div>
                <div className="mb-3">
                    <label htmlFor="old-pwd" className="form-label">Old Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="old-pwd"
                        name="old-pwd"
                        onChange={handleInputChange}
                    />
                    <span className='text-danger fs-8'>{oldPwd.error}</span>
                </div>
                <div className="mb-3">
                    <label htmlFor="new-pwd" className="form-label">New Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="new-pwd"
                        name="new-pwd"
                        onChange={handleInputChange}
                    />
                    <span className='text-danger fs-8'>{newPwd.error}</span>
                </div>
                <div className="mb-3">
                    <label htmlFor="confirm-new-pwd" className="form-label">Confirm New Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirm-new-pwd"
                        name="confirm-new-pwd"
                        onChange={handleInputChange}
                    />
                    <span className='text-danger fs-8'>{confirmNewPwd.error}</span>
                </div>
                <div className="mb-3">
                    <button className="btn btn-success p-0 px-2 py-1" onClick={handleChangePassword} disabled={loader}>
                        {
                            loader ? (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            ) : (
                                'Change Password'
                            )
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;
