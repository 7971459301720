import React, { useState } from 'react';
import { apiRequest } from '../../../api';
import { Navigate } from 'react-router-dom';
import './login.css';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import * as auth from "./../AuthReducer"
import Swal from "sweetalert2";
import validator from "validator";

const Login = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const { accessToken } = useSelector(({ auth }) => auth)

    if (localStorage.getItem('accesstoken')) {
        return <Navigate to='/events' />
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let flag = true;
        
        if(!formData.email) {
            formData["emailValidationError"] = 'Email can not be blank';
            setFormData({...formData});
            flag = false;
        }

        if(!formData.password) {
            formData["passwordError"] = 'Password can not be blank';
            setFormData({...formData});
            flag = false;
        }

        if(flag) {
            setLoader(true);
            apiRequest({ method: "post", url: "login", data: {"email": formData.email, "password": formData.password }}).then(resp => {
                localStorage.setItem('accesstoken', resp.data.token);
                localStorage.setItem('name', resp.data.name);

                // console.log(resp.data);
                dispatch(auth.actions.login(resp.data.token));
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Success',
                    text: 'Login Successful!',
                    showConfirmButton: false,
                    timer: 1500,
                    width: 400
                })
            }).catch(error => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                    width: 400
                });     
                setLoader(false);       
            });
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        const data = { ...formData };
        if(e.target.name == 'email' && !validator.isEmail(e.target.value)) {
            data["emailValidationError"] = 'Invalid email format'
        } else {
            data["emailValidationError"] = ''
        }
        data[e.target.name] = e.target.value;
        setFormData(data);
    };
    return (
        <>
            <div className="container-fluid" style={{zIndex: -1}}>
                <div className="container">
                    <div className="row height-vh login-wrapper">
                        <div className="col-12 col-sm-8 col-md-4 shadow-card">
                            <p>BYD Login</p>
                            <form className="" id="loginForm" onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">Email</label>
                                    <input type="email" name="email" className="form-control" id="username" placeholder="User Name" onChange={handleChange} />
                                    <span className='text-danger fs-11 fw-bold'>{formData.emailValidationError}</span>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="password" name="password" className="form-control" id="password" placeholder="Password" onChange={handleChange} />
                                    <span className='text-danger fs-11 fw-bold'>{formData.passwordError}</span>
                                </div>
                                <div className="mb-3">
                                    {
                                        loader ? (
                                            <>
                                                {'Please Wait.... '}
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="20"
                                                    visible={true}
                                                />
                                            </>
                                        ) : (
                                            <button type="submit" className="btn btn-primary">Log in</button>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;