const initialState = {
    sliderList: []
};


export const actionTypes = {
    sliderList: '[sliderList] Action'
}
  
export const SliderReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.sliderList: {
            return {
                ...state,
                sliderList: action.payload
            }
        }
        default: { 
            return state;
        }
    }
}

export const actions = {
    sliderList: (sliderList) => {
        return {
            type: actionTypes.sliderList,
            payload: sliderList
        }
    }
}