import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { apiRequest } from '../../../api';

const UserAdd = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const sumbitForm = (values) => {
    setLoader(true);
    apiRequest({
      method: "POST",
      url: "/register",
      data: values
    })
      .then(resp => {
        setLoader(false);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Success',
          text: resp.message,
          showConfirmButton: false,
          timer: 1500,
          width: 400
        });
        navigate('/user');
      })
      .catch(error => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message || "Something went wrong",
          width: 400
        });
      });
  };

  const onFinish = (values) => {
    sumbitForm(values);
  };

  return (
    <div className="container-fluid">
      <p className='text-end ps-5'><Button type="secondary" onClick={() => navigate(-1)}>Back</Button></p>

      <div className="container">
        <div className="row height-vh event-list-wrapper">
          <div className="col-12 col-sm-12">
            <div className="list-group">
              <div className="list-group-item active" aria-current="true">
                Add User
              </div>
              <Form
                form={form}
                onFinish={onFinish}
                className=""
              >
                <div className="mb-3" style={{ marginTop: '20px' }}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter your name' }]}
                  >
                    <Input placeholder="Enter your name" />
                  </Form.Item>
                </div>
                <div className="mb-3 style={{ marginTop: '20px' }}">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please enter your email' }]}
                  >
                    <Input type="email" placeholder="Enter email" />
                  </Form.Item>
                </div>
                <div className="mb-3 style={{ marginTop: '20px' }}">
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please enter your password' }]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </div>
                <div className="mb-3" style={{ paddingLeft: '20px' }}>
                  <Button type="primary" htmlType="submit">Add User</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default UserAdd;
