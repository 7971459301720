import React from "react";
import Loadable from "react-loadable";
import Event from "../pages/AfterAuth/Event/index";
import EventEdit from "../pages/AfterAuth/Event/edit";
import EventAdd from "../pages/AfterAuth/Event/add";
import News from "../pages/AfterAuth/News/index";
import NewsAdd from "../pages/AfterAuth/News/add";
import NewsEdit from "../pages/AfterAuth/News/edit";
import DealerListing from "../pages/AfterAuth/Dealers/index";
import DealerAdd from "../pages/AfterAuth/Dealers/add";
import DealerEdit from "../pages/AfterAuth/Dealers/edit";
import PagesAdd from "../pages/AfterAuth/Pages/add";
import PageEdit from "../pages/AfterAuth/Pages/edit";
import Page from "../pages/AfterAuth/Pages/index";
import ChangePassword from "../pages/AfterAuth/ChangePassword";
import Menu from "../pages/AfterAuth/Menu/index";
import MenuAdd from "../pages/AfterAuth/Menu/add";
import MenuEdit from "../pages/AfterAuth/Menu/edit";
import Section from "../pages/AfterAuth/Sections/index";
import SectionAdd from "../pages/AfterAuth/Sections/add";
import SectionEdit from "../pages/AfterAuth/Sections/edit";
import EnquiryNow from "../pages/AfterAuth/EnquireNow/index";
import EnquiryAdd from "../pages/AfterAuth/EnquireNow/EquiryAdd";
import Slider from "../pages/AfterAuth/Slider/index";
import SliderAdd from "../pages/AfterAuth/Slider/add";
import SliderEdit from "../pages/AfterAuth/Slider/edit";
import Reports from "../pages/AfterAuth/Reports/index";
import Media from "../pages/AfterAuth/Media/index";
import MediaAdd from "../pages/AfterAuth/Media/add";
import Career from "../pages/AfterAuth/Career/index";
import CareerAdd from "../pages/AfterAuth/Career/CareerAdd";
import CareerEdit from "../pages/AfterAuth/Career/CareerEdit";
import User from "../pages/AfterAuth/User/index";
import UserAdd from "../pages/AfterAuth/User/UserAdd";
import LoginHistory from "../pages/AfterAuth/LoginHistory/index";
import { TableRow } from "../pages/AfterAuth/User/TableRow";

function Loading() {
  return <div />;
}

const Dashboard = Loadable({
  loader: () => import("../pages/AfterAuth/Dashboard/Dashboard"),
  loading: Loading,
});

const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: Dashboard,
    permissions: [],
  },
  {
    path: "/events",
    exact: true,
    name: "Events",
    component: Event,
    permissions: [],
  },
  {
    path: "/events/edit/:id",
    exact: true,
    name: "Event Edit",
    component: EventEdit,
    permissions: [],
  },
  {
    path: "/events/add",
    exact: true,
    name: "Event Add",
    component: EventAdd,
    permissions: [],
  },
  {
    path: "/news",
    exact: true,
    name: "News",
    component: News,
    permissions: [],
  },
  {
    path: "/news/add",
    exact: true,
    name: "News Add",
    component: NewsAdd,
    permissions: [],
  },
  {
    path: "/news/edit/:id",
    exact: true,
    name: "News Edit",
    component: NewsEdit,
    permissions: [],
  },
  {
    path: "/dealer",
    exact: true,
    name: "Dealers",
    component: DealerListing,
    permissions: [],
  },
  {
    path: "/dealer/add",
    exact: true,
    name: "Dealer Add",
    component: DealerAdd,
    permissions: [],
  },
  {
    path: "/dealer/edit/:id",
    exact: true,
    name: "Dealer Edit",
    component: DealerEdit,
    permissions: [],
  },
  {
    path: "/pages",
    exact: true,
    name: "Page List",
    component: Page,
    permissions: [],
  },
  {
    path: "/pages/add",
    exact: true,
    name: "Page Add",
    component: PagesAdd,
    permissions: [],
  },
  {
    path: "/pages/edit/:id",
    exact: true,
    name: "Page Edit",
    component: PageEdit,
    permissions: [],
  },
  {
    path: "/menus",
    exact: true,
    name: "Menu List",
    component: Menu,
    permissions: [],
  },
  {
    path: "/menus/add4dev",
    exact: true,
    name: "Menu Add",
    component: MenuAdd,
    permissions: [],
  },
  {
    path: "/menus/edit/:id",
    exact: true,
    name: "Menu Edit",
    component: MenuEdit,
    permissions: [],
  },
  {
    path: "/sliders",
    exact: true,
    name: "Slider List",
    component: Slider,
    permissions: [],
  },
  {
    path: "/sliders/add",
    exact: true,
    name: "Slider Add",
    component: SliderAdd,
    permissions: [],
  },
  {
    path: "/sliders/edit/:id",
    exact: true,
    name: "Slider Edit",
    component: SliderEdit,
    permissions: [],
  },
  {
    path: "/sections",
    exact: true,
    name: "Section List",
    component: Section,
    permissions: [],
  },
  {
    path: "/sections/add",
    exact: true,
    name: "Section Add",
    component: SectionAdd,
    permissions: [],
  },
  {
    path: "/sections/edit/:id",
    exact: true,
    name: "Section Edit",
    component: SectionEdit,
    permissions: [],
  },
  {
    path: "/enquire-now",
    exact: true,
    name: "Enquiry Form List",
    component: EnquiryNow,
    permissions: [],
  },
  {
    path: "/enquire-now/add",
    exact: true,
    name: "Enquiry Add",
    component: EnquiryAdd,
    permissions: [],
  },
  {
    path: "/change-password",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
    permissions: [],
  },
  {
    path: "/mediaupload",
    exact: true,
    name: "Media",
    component: Media,
    permissions: [],
  },
  {
    path: "/mediaupload/add",
    exact: true,
    name: "Media Add",
    component: MediaAdd,
    permissions: [],
  },
  {
    path: "/reports",
    exact: true,
    name: "Reports",
    component: Reports,
    permissions: [],
  },
  {
    path: "/career",
    exact: true,
    name: "Careers",
    component: Career,
    permissions: [],
  },
  {
    path: "/career/add",
    exact: true,
    name: "Careers Add",
    component: CareerAdd,
    permissions: [],
  },
  {
    path: "/career/edit/:id",
    exact: true,
    name: "Careers Edit",
    component: CareerEdit,
    permissions: [],
  },
  {
    path: "/user",
    exact: true,
    name: "User",
    component: User,
    permissions: [],
  },
  {
    path: "/user/add",
    exact: true,
    name: "User Add",
    component: UserAdd,
    permissions: [],
  },
  {
    path: "/user/delete",
    exact: true,
    name: "User Add",
    component: TableRow,
    permissions: [],
  },
  {
    path: "/history",
    exact: true,
    name: "Login History",
    component: LoginHistory,
    permissions: [],
  },
];

export default routes;
