import React from 'react'
import { Navigate, Link } from 'react-router-dom';


export const HeaderMenu = ({ setOpen }) => {
    const actualUrl = './media/avtars/blank.png';
    const handleLogout = () => {
        const path = window.location.pathname.split('/')[1];
        localStorage.removeItem('accesstoken');
        window.location.href = '/'+path;
    }

    return (
        <div
            className='header-menu d-flex justify-content-between'
            style={{
                position: '-webkit-sticky',
                position: 'sticky',
                top: 0,
                zIndex: 10
            }}
        >
            <div style={{ marginTop: "10px", cursor: 'pointer' }}>
                <i className="fa-solid fa-bars" style={{ color: 'white' }} onClick={() => {
                    setOpen(true);
                }}></i>
            </div>
            <div className='user-icon'>
                <div style={{ marginTop: "5px" }}>
                    <img src={actualUrl} alt='user img' width='40px' height='40px' style={{ borderRadius: "5px" }} id='user-profile-img' />
                </div>
                <div className='menu-items hidden' style={{ position: 'absolute' }} id="user-profile">
                    <div className='user-info'>
                        <div className='d-flex justify-content-between'>
                            <span>User</span>
                            <span>{localStorage.getItem('name')}</span>
                        </div>
                    </div>
                    <hr className='serperator' />
                    <ul className='user-profile-items'>
                        <li className='up-subItem px-1'>
                            <Link to='/change-password' style={{ color: 'black' }}>
                                <div className="py-2">
                                    Change Password
                                </div>
                            </Link>
                        </li>
                        <li className='up-subItem px-1'>
                            <div className="py-2" onClick={handleLogout}>Logout</div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )
}
