import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SideBar from './SideBar';
import routes from '../../../config/routes';
import { HeaderMenu } from './HeaderMenu';
import { Button, Drawer } from 'antd';
import { useLocation } from 'react-router-dom';
import { Footer } from './Footer';


const Dashboard = () => {
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation(); 

    useEffect(() => {
        setOpen(false);
    }, [pathname])

    const onClose = () => {
        setOpen(false);
    }

    if (!localStorage.getItem('accesstoken')) {
        return <Navigate to='/login' />
    } else if (pathname == '/') {
        return <Navigate to='/events' />
    }

    return (
        <>
            <div className='d-flex flex-column justify-content-between height-vh'>
                <HeaderMenu setOpen={setOpen} />
                {/* <div className="container-fluid"> */}
                    {/* <div className=""> */}
                        <Drawer placement="left" onClose={onClose} open={open} closable={false}>
                            <SideBar />
                        </Drawer>
                        <div className="right-panel py-2">
                            <Routes>
                                {localStorage.getItem('accesstoken') !== null ? (
                                    routes.map((route, idx) => {
                                        if (route.component) {
                                            return (
                                                <Route
                                                    key={idx}
                                                    path={route.path}
                                                    exact={route.exact}
                                                    element={<route.component />}
                                                />
                                            );
                                        }
                                    })
                                ) : (
                                    <Navigate to="/login" />
                                )}
                            </Routes>
                        </div>
                    {/* </div> */}
                {/* </div> */}
                <Footer />
            </div>
        </>
    );
};

export default Dashboard;