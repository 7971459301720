import { useState } from "react";
import moment from "moment";
import { Switch } from "antd";
import { apiRequest } from "../../../api";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as UserReducer from "./UserReducer"; // Assuming you have a UserReducer file
import { Link } from "react-router-dom";

export const TableRow = ({ item, index }) => {
  const { id, position_name, updatedAt, sku } = item;
  const date = moment(updatedAt).format("L");
  const time = moment(updatedAt).format("LT");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getUserList = () => {
    apiRequest({
      method: "GET",
      url: "users",
    })
      .then((resp) => {
        dispatch(UserReducer.actions.userList(resp.data));
      })
      .catch((error) => {
        dispatch(UserReducer.actions.userList([]));
      });
  };

  const handleCheckedClick = () => {
    setLoading(true);
    apiRequest({
      method: "PUT",
      url: `/users/${id}`,
      data: {
        ...item,
        status: `${item.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"}`,
      },
    })
      .then(() => {
        setLoading(false);
        getUserList();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
          width: 400,
        });
      });
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this item.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        apiRequest({
          method: "DELETE",
          url: `/user/${id}`,
        })
          .then(() => {
            setLoading(false);
            getUserList();
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
              width: 400,
            });
          });
      }
    });
  };

  return (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>
        <button
          className="btn btn-danger"
          onClick={handleDelete}
          disabled={loading}
        >
          {loading ? "Deleting..." : "Delete"}
        </button>
      </td>
    </tr>
  );
};
