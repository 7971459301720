import React, {useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../logo.png';
import './style.css';

const SideBar = () => {
    return (
        <>
            <div className="left-panel height-vh">
                {/* // <div className="container-fluid"> */}
                {/* <div className="row"> */}
                <div className="col-12 col-sm-12 text-center pt-2 pb-4 logo-wrapper">
                    {/* <Link to="/" > */}
                    <img src={logo} className="img-fluid" />
                    {/* </Link> */}
                </div>
                <div className="col-12 col-sm-12 pt-4 pb-4">
                    <ul className="sidebar-nav">

                        <NavLink to="/events" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                    <i className="icon-event"></i>
                                    Events
                                </span>
                            </li>
                        </NavLink>


                        <NavLink to="/news" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                    <i className="icon-notebook"></i>
                                    News
                                </span>
                            </li>
                        </NavLink>


                        <NavLink to="/dealer" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                    <i className="fa-solid fa-handshake"></i>
                                    Dealer
                                </span>
                            </li>
                        </NavLink>

                        <NavLink to="/pages" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                    <i className="icon-doc"></i>
                                    Pages
                                </span>
                            </li>
                        </NavLink>

                        <NavLink to="/menus" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                    <i className="fa-solid fa-bars"></i>
                                    Menu
                                </span>
                            </li>
                        </NavLink>

                        <NavLink to="/sections" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                    <i className="fa-solid fa-section"></i>
                                    Sections
                                </span>
                            </li>
                        </NavLink>

                        {/* <NavLink to="/enquire-now" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                    Enquiry Now
                                </span>
                            </li>
                        </NavLink> */}

                        <NavLink to="/sliders" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                    Sliders
                                </span>
                            </li>
                        </NavLink>

                        <NavLink to="/reports" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                    Reports
                                </span>
                            </li>
                        </NavLink>

                        <NavLink to="/mediaupload" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                <i className="nav-icon fas fa-book" aria-hidden="true"></i>
                                    Media
                                </span>
                            </li>
                        </NavLink>

                        <NavLink to="/career" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                <i className="fa fa-graduation-cap" aria-hidden="true"></i>
                                    Career
                                </span>
                            </li>
                        </NavLink>

                        <NavLink to="/user" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                <i className="fa fa-user icon" aria-hidden="true"></i>
                                    User
                                </span>
                            </li>
                        </NavLink>
                        <NavLink to="/history" className={({ isActive }) => isActive ? 'menu-link menu-sub-item' : 'menu-sub-item'}>
                            <li>
                                <span className='px-4 py-2'>
                                <i className="fa fa-user icon" aria-hidden="true"></i>
                                    Login History
                                </span>
                            </li>
                        </NavLink>
                    </ul>
                </div>
                {/* // </div> */}
                {/* </div> */}
            </div>
        </>
    );
};

export default SideBar;