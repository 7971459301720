import React, { useState } from 'react';
import { apiRequest } from '../../../api';
import { useNavigate  } from "react-router-dom";
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import './style.css';
import Swal from 'sweetalert2';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EnquiryAdd = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const sumbitForm = (values) => {
    setLoader(true);
    apiRequest({
      method: "POST",
      url: "enquire-now",
      data: values
    }).then(resp => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Success',
        text: resp.message,
        showConfirmButton: false,
        timer: 1500,
        width: 400
      })
      setLoader(false);
    }).catch(error => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
        width: 400
      });
      setLoader(false);
    });
  };


  const onFinish = (values) => {
    sumbitForm(values);
  };

  const navigate = useNavigate();

  return (
    <div className="container-fluid">
      <div className="container enquiry-form">
        <p className='text-end'><Button type="secondary" onClick={() => navigate(-1)}>Back</Button></p>
        <p className='text-center fw-bolder fs-5' style={{color: 'red'}}>Enquiry Form</p>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="email_id"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="name"
            label="name"
            rules={[
              {
                required: true,
                message: 'Please input your name',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="dealer"
            label="Dealer"
            rules={[
              {
                required: true,
                message: 'Please input dealer name',
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="model_sku"
            label="Model SKU"
            rules={[
              {
                required: true,
                message: 'Please input model sku',
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="state"
            label="State Name"
            rules={[
              {
                required: true,
                message: 'Please input your state name!',
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="city"
            label="City Name"
            rules={[
              {
                required: true,
                message: 'Please input your city name!',
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="contact_number"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: 'Please input phone number!'
              },
              {
                pattern: new RegExp(/^[0-9]+$/),
                message: 'Please input numbers only!'
              },
              {
                max: 10,
                message: 'Only 10 digits are allowed!'
              }
            ]}
          >
            <Input style={{ width: '100%' }} maxLength="10" />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 1 }}>
            <Button type="primary" htmlType="submit" disabled={loader}>
              {
                loader ? (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  'Submit'
                )
              }
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default EnquiryAdd;
