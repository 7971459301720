import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import { useFormik } from 'formik';
import { pageValidation } from './../../../config/formValidationSchema/';
import { apiRequest } from '../../../api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import * as PageReducer from './PageReducer';
import * as SectionReducer from '../Sections/SectionReducer';
import Editor from './Editor';

const initialValues = {
    title: "",
    sku: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    head_tracking_code: "",
    body_tracking_code: "",
    body: "",
    status: "ACTIVE"
}

const PagesAdd = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const editor = useRef([]);
    const [content, setContent] = useState('');
    const [section, setSection] = useState('');
    const { sectionList } = useSelector(({ section }) => section);
    const [editorCount, setEditorCount] = useState([{ id: Date.now(), type: '', contentType: '', content: '' }])
    const [sliderList, setSliderList] = useState([]);

    // const config = useMemo(
    //     {
    //         readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    //         placeholder: placeholder || 'Start typings...'
    //     },
    //     [placeholder]
    // );

    useEffect(() => {
        let totalContent = content + section;
        setContent(totalContent)
    }, [section])

    const getSectionLists = () => {
        apiRequest({
            method: "GET",
            url: "section"
        }).then(resp => {
            const filterData = resp.data.filter(item => item.status === 'ACTIVE');
            dispatch(SectionReducer.actions.sectionList(filterData));
        }).catch(error => {
            dispatch(SectionReducer.actions.sectionList([]));
        });
    };
    const getSliderLists = () => {
        apiRequest({
            method: "GET",
            url: "slider"
        }).then(resp => {
            setSliderList(resp.data);
        }).catch(error => {
            console.log(error);
        });
    };
    useEffect(() => {
        getSectionLists();
        getSliderLists();
    }, []);

    const config = {
        placeholder: "Start Typing..."
    }


    const { values, errors, handleSubmit, handleChange, handleBlur } = useFormik({
        initialValues,
        validationSchema: pageValidation,
        onSubmit: (values) => {
            setLoading(true);
            apiRequest({
                method: 'POST',
                url: `/page`,
                data: {
                    ...values,
                    body: JSON.stringify(editorCount)
                }
            }).then(() => {
                setLoading(false);
                navigate('/pages')
                getPages();
            }).catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                    width: 400
                });
            })
        }
    })

    const getPages = () => {
        apiRequest({
            method: "GET",
            url: "page"
        }).then(resp => {
            dispatch(PageReducer.actions.pageList(resp.data));
        }).catch(error => {
            console.log("get all news list error", error);
        });
    };

    const handleChangeEditor = (newContent, index) => {
        const temp = [...editorCount];
        temp[index]['content'] = newContent;
        setEditorCount(temp);
    }

    const handleChangeDropdown = (event, index, slider='') => {
        const temp = [...editorCount];
        if(event.target.name === 'staticSection'){
            temp[index]['type'] = 'static';
            temp[index]['contentType'] = event.target.value;
            let cssVal = sectionList.find(item => item.id === event.target.value);
            temp[index]['contentCSS'] = cssVal.css;
        }
        if(event.target.name === 'dynamicSection'){
            temp[index]['type'] = 'dynamic';
            temp[index]['contentType'] = event.target.value;
        }
        
        temp[index]['content'] = '';
        if(slider){
            temp[index]['sliderid'] = slider;
        }

        setEditorCount(temp);
    }

    const handleChangeInput = (index, key, value) => {
        const temp = [...editorCount];
        temp[index][key] = value;
        setEditorCount(temp);
    }

    const deleteBackgroundImage = (index, key) => {
        const temp = [...editorCount];
        temp[index][key] = '';
        setEditorCount(temp);
    }

    const handleRemoveEditor = (index) => {
        const temp = [...editorCount];
        temp.splice(index, 1);

        setEditorCount(temp);
    }

    return (
        <>
            {/* <div className="container-fluid" style={{marginTop: "-20px", marginBottom: "50px"}}> */}
            <div className="container">
                <div className="row event-list-wrapper">
                    <div className="col-12 col-sm-12">
                        <p className='text-danger fw-bold fs-4'>Add Pages</p>
                        <form className="" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Page Title</label>
                                <input type="text" className="form-control" id="title" name="title" placeholder="Page Title" value={values.title} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <p className='text-danger'>{errors.title}</p>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="sku" className="form-label">Page SKU</label>
                                <input type="text" className="form-control" id="sku" name="sku" placeholder="Page SKU" value={values.sku} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <p className='text-danger'>{errors.sku}</p>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="meta_title" className="form-label">Page Meta title</label>
                                <input type="text" className="form-control" id="meta_title" name="meta_title" placeholder="Page Meta title" value={values.meta_title} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="meta_keywords" className="form-label">Page Meta Keywords</label>
                                <input type="text" className="form-control" id="meta_keywords" name="meta_keywords" placeholder="Page Meta Keywords" value={values.meta_keywords} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="meta_description" className="form-label">Page Meta Description</label>
                                <input type="text" className="form-control" id="meta_description" name="meta_description" placeholder="Page Meta Description" value={values.meta_description} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="head_tracking_code" className="form-label">Page Head Tracking Code</label>
                                <input type="text" className="form-control" id="head_tracking_code" name="head_tracking_code" placeholder="Page Head Tracking Code" value={values.head_tracking_code} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="body_tracking_code" className="form-label">Page Body Tracking Code</label>
                                <input type="text" className="form-control" id="body_tracking_code" name="body_tracking_code" placeholder="Page Body Tracking Code" value={values.body_tracking_code} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="active" className="form-label">Status(Active/Inactive): </label>
                                <div className='d-flex'>
                                    <div className="form-check">
                                        <input type="radio" className="form-check-input" id="active" name="status" value="ACTIVE" onChange={handleChange} onBlur={handleBlur} />Active
                                    </div>
                                    <div className="form-check mx-3">
                                        <input type="radio" className="form-check-input" id="inactive" name="status" value="INACTIVE" onChange={handleChange} onBlur={handleBlur} />Inactive
                                    </div>
                                </div>
                                <p className='text-danger'>{errors.status}</p>
                            </div> */}
                            <div className="mb-3">
                                <div className='my-2'>
                                    <label htmlFor="body" className="form-label">Page Body</label>
                                </div>
                                {
                                    editorCount.map((item, index) => {
                                        return (
                                            <Editor
                                                item={item}
                                                index={index}
                                                key={item.id}
                                                sectionList={sectionList}
                                                handleChangeEditor={handleChangeEditor}
                                                handleChangeDropdown={handleChangeDropdown}
                                                handleRemoveEditor={handleRemoveEditor}
                                                handleChangeInput={handleChangeInput}
                                                deleteBackgroundImage={deleteBackgroundImage}
                                                sliderList={sliderList}
                                                pageTitle={values.title}
                                            />
                                        )
                                    })
                                }
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-6">
                                    <button type='button' className='btn btn-success p-0 px-2 py-1 mx-1' onClick={() => {
                                        const newEditor = { id: Date.now(), type: '', contentType: '', content: '' };
                                        setEditorCount([...editorCount, newEditor])
                                    }}>
                                        Add New Sections
                                    </button>
                                </div>
                                <div className="col-6 col-sm-6 justify-content-end d-flex">
                                    <button type="submit" className='btn btn-success p-0 px-2 py-1' disabled={loading}>
                                        {
                                            loading ? (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            ) : (
                                                'Save'
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default PagesAdd;