import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter, Navigate, Redirect } from 'react-router-dom';
// import configureStore from '../setup/reducers/reducer';
import {store} from '../setup/store';
import Login from '../pages/Auth/Login/login';
import EventListing from '../pages/AfterAuth/Event';
import EventEdit from '../pages/AfterAuth/Event/edit';
import Dashboard from '../pages/AfterAuth/Dashboard';
import '../assets/css/coreui-icons.css';
import '../assets/css/simple-line-icons.css';
import './App.css';


function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <Routes>
            {/* <Route path='/' element={<Navigate to='/bydstaging' />} /> */}
            <Route exact path='/login' element={<Login />} />
            <Route path='*' element={<Dashboard />} />
            {/* <Navigate to='/bydstaging' /> */}
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;