import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { apiRequest } from "../../../api";
import "./style.css";
import { TableRow } from "./TableRow";
import { useDispatch, useSelector } from "react-redux";
import * as UserReducer from "./UserReducer";
import Pagination from "../CommonComponent/Pagination/Pagination";

const UserList = () => {
  const dispatch = useDispatch();
  const { userList } = useSelector(({ user }) => user);
  const [pageListing, setPageListing] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  const pageSize = 10;

  const sortByTime = (list) => {
    return list.sort((a, b) => new Date(b.lastLogin) - new Date(a.lastLogin));
  };

  const getFilteredList = (list, page) => {
    const sortedList = sortByTime(list);
    const arr = sortedList.slice(page * pageSize, page * pageSize + pageSize);
    setCurrentPageItems(arr);
  };

  const getUsersList = () => {
    apiRequest({
      method: "GET",
      url: "users",
    })
      .then((resp) => {
        const sortedList = sortByTime([...resp.data]);
        setPageListing(sortedList);
        setTotalPageCount(Math.ceil(sortedList.length / pageSize));
        getFilteredList(sortedList, currentPage);
        dispatch(UserReducer.actions.userList(sortedList));
      })
      .catch((error) => {
        dispatch(UserReducer.actions.userList([]));
      });
  };

  const handlePage = (page) => {
    setCurrentPage(page);
    getFilteredList([...pageListing], page);
  };

  const handleOnBlur = (e) => {
    let list = [...pageListing];
    if (e.target.value !== "") {
      let filteredList = list.filter((item) => {
        if (item.name) {
          if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return item;
          }
          return null;
        }
        return null;
      });
      setTotalPageCount(Math.ceil(filteredList.length / pageSize));
      setCurrentPage(0);
      getFilteredList([...filteredList], 0);
    } else {
      setTotalPageCount(Math.ceil(pageListing.length / pageSize));
      setCurrentPage(0);
      getFilteredList([...pageListing], 0);
    }
  };

  const handleOnKeyDown = (e) => {
    let list = [...pageListing];
    if (e.keyCode === 13) {
      if (e.target.value !== "") {
        let filteredList = list.filter((item) => {
          if (item.name) {
            if (
              item.name.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
              return item;
            }
            return null;
          }
          return null;
        });
        setTotalPageCount(Math.ceil(filteredList.length / pageSize));
        setCurrentPage(0);
        getFilteredList([...filteredList], 0);
      } else {
        setTotalPageCount(Math.ceil(pageListing.length / pageSize));
        setCurrentPage(0);
        getFilteredList([...pageListing], 0);
      }
    }
  };

  useEffect(() => {
    getUsersList();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row height-vh event-list-wrapper">
            <div className="col-12 col-sm-12">
              <div className="list-group">
                <div className="list-group-item active" aria-current="true">
                  Users Lists
                  <Link to="/User/add">Create User</Link>
                </div>
                <div className="d-flex justify-content-end mb-4 mt-4">
                  <div className="tableSearch">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="form-control"
                      onBlur={handleOnBlur}
                      onKeyDown={handleOnKeyDown}
                    />
                  </div>
                </div>
                <table className="table table-striped table-hover text-center">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageItems.map((item, index) => (
                      <TableRow
                        item={item}
                        index={currentPage * pageSize + index}
                        key={`user__${index}`}
                      />
                    ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPageCount}
                  pageCallBack={handlePage}
                />
              </div>
              {currentPageItems.length === 0 && (
                <div className="text-center">-- no record found --</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
