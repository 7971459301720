import React, { useState, useRef } from 'react';
import { apiRequest } from '../../../api';
import { useNavigate } from "react-router-dom";
import JoditEditor from 'jodit-react';
import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import './style.css';
import Swal from 'sweetalert2';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EnquiryAdd = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const editor = useRef([]);
  const [content, setContent] = useState('');
  const [status, setStatus] = useState('INACTIVE');
  const [rolesError, setRolesError] = useState('');
  const navigate = useNavigate();
  const config = {
    placeholder: "Start Typing..."
  }

  const sumbitForm = (value) => {

    setLoader(true);
    apiRequest({
      method: "POST",
      url: "career",
      data: value
    }).then(resp => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Success',
        text: resp.message,
        showConfirmButton: false,
        timer: 1500,
        width: 400
      })
      setLoader(false);
      navigate('/career')
    }).catch(error => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
        width: 400
      });
      setLoader(false);
    });
  };


  const onFinish = (values) => {
    if(!editor.current.value) {
      setRolesError('Please input roles and responsibilities')
    }
    const value = {
      ...values,
      roles_responsibilities: editor.current.value,
      title: "tilte",
      sku: Date.now(),
      content: "content",
      images: "",
      status
    }
    sumbitForm(value);
  };

  const handleChange = (e) => {
    setStatus(e.target.value);
  }

  return (
    <div className="container-fluid">
      <div className="container enquiry-form">
        <p className='text-end'><Button type="secondary" onClick={() => navigate(-1)}>Back</Button></p>
        <p className='text-center fw-bolder fs-5' style={{ color: 'red' }}>Career Form</p>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="position_name"
            label="Position Name"
            rules={[
              {
                required: true,
                message: 'Please input Position Name',
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="division_name"
            label="Division Name"
            rules={[
              {
                required: true,
                message: 'Please input Division Name',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: 'Please input address',
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="salary"
            label="Salary"
            rules={[
              {
                required: true,
                message: 'Please input salary',
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="experience"
            label="Experience"
            rules={[
              {
                required: true,
                message: 'Please input experience',
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="industry_preferred"
            label="Industry Preferred"
            rules={[
              {
                required: true,
                message: 'Please input industry preferred',
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="qualifications"
            label="Qualifications"
            rules={[
              {
                required: true,
                message: 'Please input qualifications'
              }
              // ,
              // {
              //   pattern: new RegExp(/^[0-9]+$/),
              //   message: 'Please input numbers only!'
              // },
              // {
              //   max: 10,
              //   message: 'Only 10 digits are allowed!'
              // }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="competencies"
            label="Competencies"
            rules={[
              {
                required: true,
                message: 'Please input competencies'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="disclaimer"
            label="Disclaimer"
          >
            <Input />
          </Form.Item>

          <span className='text-danger mx-1'>*</span>
          <span>Roles & Responsibilites</span>
          <div className='my-4'>
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={newContent => { }}
            />
          </div>
          <p className='text-danger'>{rolesError}</p>

          <div className="mb-3">
            <label htmlFor="active" className="form-label">Status(Active/Inactive): </label>
            <div className='d-flex'>
              <div className="form-check">
                <input type="radio" className="form-check-input" id="active" name="status" value="ACTIVE" onChange={handleChange} checked={status == 'ACTIVE'} />Active
              </div>
              <div className="form-check mx-3">
                <input type="radio" className="form-check-input" id="inactive" name="status" value="INACTIVE" onChange={handleChange} checked={status == 'INACTIVE'} />Inactive
              </div>
            </div>
          </div>

          <Form.Item wrapperCol={{ span: 12, offset: 1 }}>
            <Button type="primary" htmlType="submit" disabled={loader}>
              {
                loader ? (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  'Submit'
                )
              }
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default EnquiryAdd;
