const initialState = {
    userList: []
};


export const actionTypes = {
    userList: '[userList] Action'
}
  
export const UserReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.userList: {
            return {
                ...state,
                userList: action.payload
            }
        }
        default: { 
            return state;
        }
    }
}

export const actions = {
    userList: (userList) => {
        return {
            type: actionTypes.userList,
            payload: userList
        }
    }
}