const initialState = {
    newsList: []
};


export const actionTypes = {
    newsList: '[NewsList] Action'
}
  
export const NewsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.newsList: {
            return {
                ...state,
                newsList: action.payload
            }
        }
        default: { 
            return state;
        }
    }
}

export const actions = {
    newsList: (newsList) => {
        return {
            type: actionTypes.newsList,
            payload: newsList
        }
    }
}