import React, { useState, useEffect } from 'react'
import { apiRequest } from '../../../api';
import { DatePicker } from 'antd';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';

const { RangePicker } = DatePicker;

export const TableRow = ({ item, index }) => {
    const [dateRange, setDateRange] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [limit, setLimit] = useState(0);
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState({headers: [], data: []})

    const dateChange = (date, dateString) => {
        setDateRange(dateString);
    }

    const getData = () => {
        const reqUrl = `${item.url}?fromDate=${dateRange[0]}&toDate=${dateRange[1]}&limit=${limit}`
        setLoading(true);
        apiRequest({
            method: "GET",
            url: reqUrl
        }).then((resp) => {
            setCsvData(resp.data);
            setLoading(false);
        }).catch(error => {
            setCsvData([]);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
            setLoading(false);
        });
    };

    // useEffect(() => {
    //     getData();
    // },[])

    // let headers = [];
    // let data = [];

    useEffect(() => {
        if (csvData.length > 0) {
            const dataKeys = Object.keys(csvData[0]);
            // const dataValues = Object.values(csvData);
            // const dataKeyValue = Object.entries(csvData);
            let headers = []
            // const headerObj = {}
            dataKeys.forEach((item, index) => {
               headers.push({label: item, key: item});
            })

            let data = []
            csvData.forEach((item, index) => {
                const newObj = {};
                for(let [key, value] of Object.entries(item)) {
                    newObj[key] = value;
                }
                data.push(newObj);
            })
            setReportData({headers, data})
        }
    }, [csvData])

    const handleClick = () => {
        getData();
    }

    const csvReport = {
        headers: reportData.headers,
        data: reportData.data,
        filename: item.csvFileName
    }

    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{item.name}</td>
            <td>
                <div
                    className='mx-2'
                >
                    <RangePicker onChange={dateChange} />
                </div>
            </td>
            <td>
                <div className="mb-3 d-flex flex-column mx-2" style={{ justifyContent: 'center' }}>
                    <input
                        type='number'
                        name='limit'
                        className="form-control"
                        id='limit'
                        placeholder='Limit'
                        min={1}
                        max={100}
                        value={limit}
                        onChange={(e) => setLimit(e.target.value)}
                    />
                </div>
            </td>
            <td>
                <div>
                    <button
                        className='btn btn-secondary p-0 px-2 py-1 mx-2 my-1'
                        onClick={handleClick}
                    // disabled={dateRange.length == 0 ? 'false' : 'true'}
                    >
                        {
                            loading ? (
                                <span className='indicator-progress'>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            ) : (
                                'Submit'
                            )
                        }
                    </button>
                    {
                        csvData.length > 0 && (
                            <button
                                className='btn btn-secondary p-0 px-2 py-1 mx-2 my-1'
                            >
                                <CSVLink {...csvReport}>
                                    <span style={{ color: "white" }}>Export</span>
                                </CSVLink>
                            </button>
                        )
                    }

                </div>
            </td>
        </tr>
    )
}
