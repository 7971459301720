import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../../api';
import './style.css';
import { TableRow } from './TableRow';

const Report = () => {
    // const reportData = ['Enquiry Now', 'News Letter', 'Support Ticket', 'Contact Us']
    const reportData = [{name: 'Enquiry Now', url: 'reports/enquireNow', csvFileName: 'enquiry_now.csv'}, {name: 'News Letter', url: 'reports/newsLetter', csvFileName: 'news_letter.csv'}, {name: 'Quick-mail', url: 'reports/supportTicket', csvFileName: 'support_ticket.csv'}, {name: 'Support Ticket', url: 'reports/contactUs', csvFileName: 'contact_us.csv'}]
    
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className='text-center fs-5 fw-bold my-2'>Export Data</div>
                            <div className="list-group">
                                <table className="table table-striped table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Date Range</th>
                                            <th scope="col">Limit</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportData.map((item, index) => (
                                            <TableRow item={item} index={index} key={`report_${index}`} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Report;