import React, { useEffect, useState } from 'react';
import './Pagination.css';

const Pagination = ({currentPage, totalPages, pageCallBack}) => {
    const [pageLists, setPageLists] = useState([]);
    const visibleItems = 5;
    const createPagesCounter = () => {
        const pages = [];
        for(let i = currentPage - visibleItems; i < totalPages; i++){
            if((i >= 0) && (i <= totalPages)){
                if(currentPage > Math.floor(visibleItems/2) && currentPage < (totalPages - Math.floor(visibleItems/2))){
                    if(Math.abs(i - currentPage) < Math.ceil(visibleItems/2)){
                        pages.push(i);
                    }
                }else{
                    if(Math.abs(i - currentPage) < visibleItems){
                        pages.push(i);
                    }
                }
                if(pages.length === visibleItems){
                    break;
                }
            }
        }
        setPageLists(pages);
    };
    useEffect(() => {
        createPagesCounter();
    }, [currentPage, totalPages]);
    return (
        <div className="paginationWrapper d-flex">
            {totalPages > 0 && (<>
                <button className="roundBtn circle" disabled={currentPage === 0} onClick={() => pageCallBack(currentPage - 1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                    </svg>
                </button>
                {totalPages > visibleItems && (<div className="roundBtn dots">
                    ...
                </div>)}
                {pageLists.map((item) => (
                    <button
                        className={currentPage === item ? "active roundBtn circle" : "roundBtn circle"}
                        key={`page_${item}`}
                        onClick={() => pageCallBack(item)}
                    >
                        {item + 1}
                    </button>
                ))}
                {totalPages > visibleItems && (<div className="roundBtn dots">
                    ...
                </div>)}
                <button className="roundBtn circle" disabled={currentPage === (totalPages - 1)} onClick={() => pageCallBack(currentPage + 1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
            </>)}
        </div>
    );
};

export default Pagination;