import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { apiRequest } from '../../../api';

const MenuEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname.split('/');
    const menuTitle = pathname[pathname.length - 1];
    const [menuId, setMenuId] = useState('');
    const [menuFormData, setMenuFormData] = useState({
        title: '',
        status: 'ACTIVE',
        body: [
            {label: '', url: '', hasChild: false, child: [], nestingDepth: 0},
        ],
        sku: 'HEADER_CENTER_MENU'
    });
    const enableHasChild = ['HEADER_CENTER_MENU'];
    const iconOptions = [
        {label: 'Tumblr', value: 'icon-social-tumblr'},
        {label: 'Twitter', value: 'icon-social-twitter'},
        {label: 'Facebook', value: 'icon-social-facebook'},
        {label: 'Instagram', value: 'icon-social-instagram'},
        {label: 'Linkedin', value: 'icon-social-linkedin'},
        {label: 'Pinterest', value: 'icon-social-pinterest'},
        {label: 'Google', value: 'icon-social-google'},
        {label: 'Reddit', value: 'icon-social-reddit'},
        {label: 'Skype', value: 'icon-social-skype'},
        {label: 'Dribbble', value: 'icon-social-dribbble'},
        {label: 'Youtube', value: 'icon-social-youtube'},
        {label: 'Dropbox', value: 'icon-social-dropbox'},
        {label: 'Vkontakte', value: 'icon-social-vkontakte'},
        {label: 'Steam', value: 'icon-social-steam'},
        {label: 'Soundcloud', value: 'icon-social-soundcloud'},
        {label: 'Spotify', value: 'icon-social-spotify'},
        {label: 'Foursqare', value: 'icon-social-foursqare'},
        {label: 'Stumbleupon', value: 'icon-social-stumbleupon'}
    ];

    useEffect(() => {
        apiRequest({
            method: "GET",
            url: `/menu/${atob(menuTitle)}`
        }).then(resp => {
            setMenuId(resp.data.id);
            setMenuFormData({
                title: resp.data.title,
                status: resp.data.status,
                sku: resp.data.sku,
                body: JSON.parse(resp.data.body)
            });
        }).catch(error => {
            console.log("get all menu list error", error);
        });
    }, []);

    const addChild = (index) => {
        const data = {...menuFormData};
        if(index.length === 1){
            data.body[index].child.push({
                label: '', url: '', hasChild: false, child: [], nestingDepth: 1, icon: ''
            });
            setMenuFormData({...data});
        }else{
            const indexing = index.split('_');
            const nestedDepth = index.split('_');
            nestedDepth.splice(nestedDepth.length - 1, 1);
            let currentData = [];
            const currentDataStack = [];
            nestedDepth.forEach((item, ind) => {
                if(ind === 0){
                    currentData = data.body[item];
                    currentDataStack.push(data.body);
                }else{
                    if(currentData.child && currentData.child.length > 0 && ind < nestedDepth.length){
                        currentDataStack.push(currentData.child);
                        currentData = currentData.child[item];
                    }
                }
            });
            currentData.child[indexing[indexing.length - 1]].child.push({
                label: '', url: '', hasChild: false, child: [], nestingDepth: indexing.length - 1, icon: ''
            });
    
            nestedDepth.reverse();
            currentDataStack.reverse();
            let newData = {...currentData};
            nestedDepth.forEach((item, ind) => {
                if(ind === 0){
                    currentDataStack[ind][item] = newData;
                }else{
                    currentDataStack[ind][item].child = currentDataStack[ind-1];
                }
            });
            currentDataStack.reverse();
            data.body = currentDataStack[0];
            setMenuFormData({...data});
        }
    };
    const addLabels = () => {
        const data = {...menuFormData};
        data.body.push({
            label: '', url: '', hasChild: false, child: [], nestingDepth: 0, icon: ''
        });
        setMenuFormData(data);
    };
    const removeLabels = (index) => {
        const data = {...menuFormData};
        data.body.splice(index, 1);
        setMenuFormData(data);
    };
    const removeChildLabels = (index) => {
        const data = {...menuFormData};
        const indexing = index.split('_');
        const nestedDepth = index.split('_');
        nestedDepth.splice(nestedDepth.length - 1, 1);
        let currentData = [];
        const currentDataStack = [];
        nestedDepth.forEach((item, ind) => {
            if(ind === 0){
                currentData = data.body[item];
                currentDataStack.push(data.body);
            }else{
                if(currentData.child && currentData.child.length > 0 && ind < nestedDepth.length){
                    currentDataStack.push(currentData.child);
                    currentData = currentData.child[item];
                }
            }
        });
        const child = currentData.child;
        child.splice(indexing[indexing.length - 1], 1);
        currentData.child = child;

        nestedDepth.reverse();
        currentDataStack.reverse();
        let newData = {...currentData};
        nestedDepth.forEach((item, ind) => {
            if(ind === 0){
                currentDataStack[ind][item] = newData;
            }else{
                currentDataStack[ind][item].child = currentDataStack[ind-1];
            }
        });
        currentDataStack.reverse();
        data.body = currentDataStack[0];
        setMenuFormData({...data});
    };
    const handleChange = (e) => {
        const data = {...menuFormData};
        data[e.target.name] = e.target.value;
        setMenuFormData(data);
    }
    const handleInputChange = (e, index) => {
        const data = {...menuFormData};
        if(index.length === 1){
            data.body[index][e.target.name] = e.target.value;
            setMenuFormData({...data});
        }else{
            const indexing = index.split('_');
            const nestedDepth = index.split('_');
            nestedDepth.splice(nestedDepth.length - 1, 1);
            let currentData = [];
            const currentDataStack = [];
            nestedDepth.forEach((item, ind) => {
                if(ind === 0){
                    currentData = data.body[item];
                    currentDataStack.push(data.body);
                }else{
                    if(currentData.child && currentData.child.length > 0 && ind < nestedDepth.length){
                        currentDataStack.push(currentData.child);
                        currentData = currentData.child[item];
                    }
                }
            });
            currentData.child[indexing[indexing.length - 1]][e.target.name] = e.target.value;
    
            nestedDepth.reverse();
            currentDataStack.reverse();
            let newData = {...currentData};
            nestedDepth.forEach((item, ind) => {
                if(ind === 0){
                    currentDataStack[ind][item] = newData;
                }else{
                    currentDataStack[ind][item].child = currentDataStack[ind-1];
                }
            });
            currentDataStack.reverse();
            data.body = currentDataStack[0];
            setMenuFormData({...data});
        }
    };
    const handleHasChild = (e, parentIndex, index = null) => {
        const data = {...menuFormData};
        if(index !== null){
            data.body[parentIndex].child[index]['hasChild'] = e.target.checked;
            setMenuFormData(data);
            return false;
        }
        data.body[parentIndex][e.target.name] = e.target.checked;
        setMenuFormData(data);
    };
    const handleIconChange = (e, index) => {
        const data = {...menuFormData};
        data.body[index][e.target.name] = e.target.value;
        setMenuFormData(data);
    };
    const handleSave = () => {
        apiRequest({
            method: 'PUT',
            url: `/menu/${menuId}`,
            data: {
                ...menuFormData,
                body: JSON.stringify(menuFormData.body)
            },
        }).then(() => {
            navigate('/menus')
        }).catch((error) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
        })
    };
    const renderChildLabel = (childLabel, parentIndex, indexing) => {
        return childLabel.map((item, index) => (
            <div
                className="d-flex align-items-center mt-10 custom-row ph-20"
                key={`menu_label_${parentIndex}_${index}`}
                data-index={`${indexing}_${index}`}
            >
                <div className="col-xs-3 col-sm-3">
                    <input type="text" className="form-control" name="label" placeholder="Label" value={item.label} onChange={(e) => handleInputChange(e, indexing+'_'+index)} autoComplete="off" />
                </div>
                <div className="col-xs-3 col-sm-3">
                    <input type="text" className="form-control" name="url" placeholder="URL" value={item.url} onChange={(e) => handleInputChange(e, indexing+'_'+index)} autoComplete="off" />
                </div>
                {enableHasChild.includes(menuFormData.sku) && (<div className="col-xs-3 col-sm-2">
                    <label htmlFor={`hasChild${index}`}>
                        <input type="text" className="form-control" name="sortkey" placeholder="Position" value={item.sortkey} onChange={(e) => handleInputChange(e, indexing+'_'+index)} autoComplete="off" />
                    </label>
                </div>)}
                {item.nestingDepth < 2 && enableHasChild.includes(menuFormData.sku) && (<div className="col-xs-3 col-sm-2">
                    <label htmlFor={`hasChild${parentIndex}_${index}`}>
                        <input type="checkbox" className="form-check-input mr-10" id={`hasChild${parentIndex}_${index}`} name="" checked={item.hasChild} onChange={(e) => handleHasChild(e, parentIndex, index)} />Has Child
                    </label>
                </div>)}
                <div className="col-xs-3 col-sm-2">
                    {item.hasChild && (<button
                        type="button"
                        className="btn btn-primary mr-10"
                        onClick={() => addChild(indexing+'_'+index)}
                    >
                        Add Child
                    </button>)}
                    {childLabel.length > 0 && (<button
                        type="button"
                        className="btn"
                        onClick={() => removeChildLabels(indexing+'_'+index)}
                    >
                        <i className="icon-close"></i>
                    </button>)}
                </div>
                {renderChildLabel(item.child, index, indexing+'_'+index)}
            </div>
        ));
    };
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className="list-group">
                                <div className="list-group-item active" aria-current="true">
                                    Add Menu
                                </div>
                                <form className="">
                                    <div className="mb-3">
                                        <label className="form-label">Menu Title</label>
                                        <input type="text" className="form-control" name="title" placeholder="Menu Title" value={menuFormData.title} onChange={handleChange} autoComplete="off" />
                                    </div>
                                    {/* <div className="mb-3">
                                        <label htmlFor="position" className="form-label">Position: </label>
                                        <div className='d-flex'>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input" id="position" name="sku" value="HEADER_CENTER_MENU" checked={menuFormData.sku === 'HEADER_CENTER_MENU'} onChange={handleChange} />Header Center Menu
                                            </div>
                                            <div className="form-check mx-3">
                                                <input type="radio" className="form-check-input" id="inactive" name="sku" value="HEADER_RIGHT_MENU" checked={menuFormData.sku === 'HEADER_RIGHT_MENU'} onChange={handleChange} />Header Right Menu
                                            </div>
                                            <div className="form-check mx-3">
                                                <input type="radio" className="form-check-input" id="inactive" name="sku" value="FOOTER_MENU" checked={menuFormData.sku === 'FOOTER_MENU'} onChange={handleChange} />Footer Menu
                                            </div>
                                            <div className="form-check mx-3">
                                                <input type="radio" className="form-check-input" id="inactive" name="sku" value="FOOTER_SOCIAL_MENU" checked={menuFormData.sku === 'FOOTER_SOCIAL_MENU'} onChange={handleChange} />Footer Social Menu
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="active" className="form-label">Status(Active/Inactive): </label>
                                        <div className='d-flex'>
                                            <div className="form-check">
                                                <input type="radio" className="form-check-input" id="active" name="status" value="ACTIVE" checked={menuFormData.status === 'ACTIVE'} onChange={handleChange} />Active
                                            </div>
                                            <div className="form-check mx-3">
                                                <input type="radio" className="form-check-input" id="inactive" name="status" value="INACTIVE" checked={menuFormData.status === 'INACTIVE'} onChange={handleChange} />Inactive
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="mb-3">
                                        <label htmlFor="active" className="form-label">Details : </label>
                                        {menuFormData.body.map((item, index) => (
                                            <div className="d-flex align-items-center mb-10 custom-row" key={`menu_label_${index}`}>
                                                <div className="col-xs-3 col-sm-3">
                                                    <input type="text" className="form-control" name="label" placeholder="Label" value={item.label} onChange={(e) => handleInputChange(e, index.toString())} autoComplete="off" />
                                                </div>
                                                <div className="col-xs-3 col-sm-3">
                                                    <input type="text" className="form-control" name="url" placeholder="URL" value={item.url} onChange={(e) => handleInputChange(e, index.toString())} autoComplete="off" />
                                                </div>
                                                {menuFormData.sku === 'FOOTER_SOCIAL_MENU' && (<div className="col-xs-3 col-sm-3">
                                                    <select
                                                        className="form-select form-select-solid"
                                                        data-control="select2"
                                                        data-hide-search="true"
                                                        name="icon"
                                                        tabindex="-1"
                                                        aria-hidden="false"
                                                        value={item.icon}
                                                        onChange={(e) => handleIconChange(e, index)}
                                                    >
                                                        <option value="">Select Icons</option>
                                                        {
                                                            iconOptions.map((item, index) => {
                                                                return (
                                                                    <option value={item.value} key={`icon_${index}`}>
                                                                        {item.label}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>)}
                                                {enableHasChild.includes(menuFormData.sku) && (<div className="col-xs-3 col-sm-2">
                                                    <label htmlFor={`hasChild${index}`}>
                                                        <input type="text" className="form-control" name="sortkey" placeholder="Position" value={item.sortkey} onChange={(e) => handleInputChange(e, index.toString())} autoComplete="off" />
                                                    </label>
                                                </div>)}
                                                {menuFormData.sku === 'HEADER_RIGHT_MENU' && (<div className="col-xs-3 col-sm-2">
                                                    <label htmlFor={`hasChild${index}`}>
                                                        <input type="text" className="form-control" name="iconImage" placeholder="Image Url" value={item.iconImage} onChange={(e) => handleInputChange(e, index.toString())} autoComplete="off" />
                                                    </label>
                                                </div>)}
                                                {enableHasChild.includes(menuFormData.sku) && (<div className="col-xs-3 col-sm-2">
                                                    <label htmlFor={`hasChild${index}`}>
                                                        <input type="checkbox" className="form-check-input mr-10" id={`hasChild${index}`} name="hasChild" checked={item.hasChild} onChange={(e) => handleHasChild(e, index)} />Has Child
                                                    </label>
                                                </div>)}
                                                <div className="col-xs-3 col-sm-2">
                                                    {item.hasChild && (menuFormData.sku !== 'FOOTER_SOCIAL_MENU') && (<button
                                                        type="button"
                                                        className="btn btn-primary mr-10"
                                                        onClick={() => addChild(index.toString())}
                                                    >
                                                        Add Child
                                                    </button>)}
                                                    {menuFormData.body.length > 1 && (<button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => removeLabels(index)}
                                                    >
                                                        <i className="icon-close"></i>
                                                    </button>)}
                                                </div>
                                                {renderChildLabel(item.child, index, index.toString())}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mb-3 d-flex justify-content-between fluid-100 border-top">
                                        <button
                                            type="button"
                                            onClick={addLabels}
                                            className="btn btn-success p-0 px-2 py-1"
                                        >
                                            Add Label
                                        </button>
                                        <button
                                            type="button"
                                            className='btn btn-success p-0 px-2 py-1'
                                            onClick={handleSave}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MenuEdit;