export const TableRow = ({ item, index }) => {
    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{item.name}</td>
            <td>{item.email_id}</td>
            <td>{item.contact_number}</td>
            <td>{item.dealer}</td>
            <td>{item.model_sku}</td>
            <td>{item.state}</td>
            <td>{item.city}</td>
            <td>{item.status}</td>
        </tr>
    )
}
