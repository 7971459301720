import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { apiRequest } from '../../../api';
import './style.css';
import { TableRow } from './TableRow';
import { useDispatch, useSelector } from 'react-redux';
import * as DealerReducer from './DealerReducer';
import Pagination from '../CommonComponent/Pagination/Pagination';

const DealerListing = () => {
    const dispatch = useDispatch();
    const { dealerList } = useSelector(({ dealer }) => dealer);
    const [pageListing, setPageListing] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const pageSize = 10;

    const getFilteredList = (list, page) => {
        let lastPageItems = 0;
        lastPageItems = Math.ceil(list.length % pageSize);
        const arr = list.slice((page*pageSize), (((page)*pageSize) + pageSize));
        setCurrentPageItems(arr);
    };
    const getDealerLists = () => {
        apiRequest({
            method: "GET",
            url: "dealers"
        }).then(resp => {
            getFilteredList([...resp.data], currentPage);
            setPageListing(resp.data);
            setTotalPageCount(Math.ceil(resp.data.length/pageSize));
            dispatch(DealerReducer.actions.dealerList(resp.data));
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        getDealerLists();
    }, []);

    const handlePage = (page) => {
        setCurrentPage(page);
        getFilteredList([...pageListing], page);
    };

    const handleOnBlur = (e) => {
        let list = [...pageListing];
        if(e.target.value !== ''){
            let filteredList = list.filter(item => {
                if(item.name){
                    if(item.name.toLowerCase().includes(e.target.value.toLowerCase())){
                        return item;
                    }
                    return null;
                }
                return null;
            });
            if(filteredList.length === 0){
                filteredList = list.filter(item => {
                    if(item.state){
                        if(item.state.toLowerCase().includes(e.target.value.toLowerCase())){
                            return item;
                        }
                        return null;
                    }
                    return null;
                });
                if(filteredList.length === 0){
                    filteredList = list.filter(item => {
                        if(item.city){
                            if(item.city.toLowerCase().includes(e.target.value.toLowerCase())){
                                return item;
                            }
                            return null;
                        }
                        return null;
                    });
                }
            }
            setTotalPageCount(Math.ceil(filteredList.length/pageSize));
            setCurrentPage(0);
            getFilteredList([...filteredList], 0);
        }else{
            setTotalPageCount(Math.ceil(pageListing.length/pageSize));
            setCurrentPage(0);
            getFilteredList([...pageListing], 0);
        }
        
    };

    const handleOnKeyDown = (e) => {
        let list = [...pageListing];
        if(e.keyCode === 13){
            if(e.target.value !== ''){
                let filteredList = list.filter(item => {
                    if(item.name){
                        if(item.name.toLowerCase().includes(e.target.value.toLowerCase())){
                            return item;
                        }
                        return null;
                    }
                    return null;
                });
                if(filteredList.length === 0){
                    filteredList = list.filter(item => {
                        if(item.state){
                            if(item.state.toLowerCase().includes(e.target.value.toLowerCase())){
                                return item;
                            }
                            return null;
                        }
                        return null;
                    });
                    if(filteredList.length === 0){
                        filteredList = list.filter(item => {
                            if(item.city){
                                if(item.city.toLowerCase().includes(e.target.value.toLowerCase())){
                                    return item;
                                }
                                return null;
                            }
                            return null;
                        });
                    }
                }
                setTotalPageCount(Math.ceil(filteredList.length/pageSize));
                setCurrentPage(0);
                getFilteredList([...filteredList], 0);
            }else{
                setTotalPageCount(Math.ceil(pageListing.length/pageSize));
                setCurrentPage(0);
                getFilteredList([...pageListing], 0);
            }
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className="list-group">
                                <div className="list-group-item active" aria-current="true">
                                    Dealer Lists
                                    <Link to="/dealer/add">Create Dealer</Link>
                                </div>
                                <div className="d-flex justify-content-end mb-4 mt-4">
                                    <div className="tableSearch">
                                        <input
                                            type="text" 
                                            placeholder="Search..." 
                                            className="form-control" 
                                            onBlur={handleOnBlur}
                                            onKeyDown={handleOnKeyDown}
                                        />
                                    </div>
                                </div>
                                {currentPageItems.length ? (<table className="table table-striped table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Dealer Code</th>
                                            <th scope="col">Dealer Name</th>
                                            <th scope="col">Dealer State</th>
                                            <th scope="col">Dealer City</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Updated_By</th>
                                            <th scope="col">Action</th>
                                            {/* <th scope="col">Active/Inactive</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageItems.map((item, index) => (
                                            <TableRow item={item} index={(currentPage*pageSize)+ index} key={`event_${index}`} />
                                        ))}
                                    </tbody>
                                </table>) : null}
                                {
                                    currentPageItems.length == 0 && (
                                        <div className='text-center mb-5'>
                                            -- no record found --
                                        </div>
                                    )
                                }
                                <Pagination currentPage={currentPage} totalPages={totalPageCount} pageCallBack={handlePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DealerListing;