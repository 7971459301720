import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../../api'; // Replace with your actual API module
import { useSelector, useDispatch } from 'react-redux';
import * as LoginReducer from './LoginReducer'; // Replace with your actual reducer import
import Pagination from '../CommonComponent/Pagination/Pagination';
import './style.css';
import { TableRow } from './TableRow';

const LoginHistory = () => {
    const dispatch = useDispatch();
    const { userList } = useSelector(({ user }) => user); // Assuming your user state is managed by Redux

    const [pageListing, setPageListing] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const pageSize = 10;

    const sortByTime = (list) => {
        return list.sort((a, b) => new Date(b.lastLogin) - new Date(a.lastLogin));
    };

    const getUsersList = () => {
        apiRequest({
            method: "GET",
            url: "history" // Adjust the endpoint based on your API
        }).then(resp => {
            const updatedList = updateLoginData([...userList], resp.data);
            setPageListing(sortByTime(updatedList));
            setTotalPageCount(Math.ceil(updatedList.length / pageSize));
            getFilteredList(updatedList, currentPage);
            dispatch(LoginReducer.actions.userList(updatedList)); // Assuming this updates Redux state
        }).catch(error => {
            console.error("Error fetching user list:", error);
        });
    };

    const updateLoginData = (existingList, newData) => {
        const updatedList = [...existingList, ...newData];
        return updatedList;
    };

    const handlePage = (page) => {
        setCurrentPage(page);
        getFilteredList([...pageListing], page);
    };

    const handleOnBlur = (e) => {
        let list = [...pageListing];
        if (e.target.value !== '') {
            let filteredList = list.filter(item =>
                item.name.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setTotalPageCount(Math.ceil(filteredList.length / pageSize));
            setCurrentPage(0);
            getFilteredList([...filteredList], 0);
        } else {
            setTotalPageCount(Math.ceil(pageListing.length / pageSize));
            setCurrentPage(0);
            getFilteredList([...pageListing], 0);
        }
    };

    const handleOnKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleOnBlur(e);
        }
    };

    const getFilteredList = (list, page) => {
        const sortedList = sortByTime(list);
        const arr = sortedList.slice(page * pageSize, (page * pageSize) + pageSize);
        setCurrentPageItems(arr);
    };

    useEffect(() => {
        getUsersList();
    }, []);

    return (
        <div className="container-fluid">
            <div className="container">
                <div className="row height-vh event-list-wrapper">
                    <div className="col-12 col-sm-12">
                        <div className="list-group">
                            <div className="list-group-item active" aria-current="true">
                                Users Login History
                            </div>
                            <div className="d-flex justify-content-end mb-4 mt-4">
                                <div className="tableSearch">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        className="form-control"
                                        onBlur={handleOnBlur}
                                        onKeyDown={handleOnKeyDown}
                                    />
                                </div>
                            </div>
                            <table className="table table-striped table-hover text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">LastLogin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentPageItems.map((item, index) => (
                                        <TableRow item={item} index={currentPage * pageSize + index} key={`user_${index}`} />
                                    ))}
                                </tbody>
                            </table>
                            <Pagination currentPage={currentPage} totalPages={totalPageCount} pageCallBack={handlePage} />
                            {currentPageItems.length === 0 && (
                                <div className="text-center">-- no record found --</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginHistory;
