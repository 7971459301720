import React, { useState } from 'react'
import { Switch } from 'antd';
import { Link } from 'react-router-dom';
import { apiRequest } from '../../../api';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import * as EventReducer from './EventReducer';

export const TableRow = ({ item, index }) => {
    // const [checked, setChecked] = useState(item.status);
    const [loading, setLoading] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    const dispatch = useDispatch();

    const getEventsList = () => {
        apiRequest({
            method: 'GET',
            url: 'events'
        })
            .then((resp) => {
                dispatch(EventReducer.actions.eventList(resp.data));
            })
            .catch((error) => {
                console.log("getting all events error", error)
            })
    }

    const handleCheckedClick = () => {
        setLoading(true);
        apiRequest({
            method: 'PUT',
            url: `/events/${item.id}`,
            data: {
                ...item,
                status: `${item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`
            }
        }).then(() => {
            setLoading(false);
            getEventsList();
        }).catch((error) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
        })
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this item.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setDelLoading(true);
                apiRequest({
                    method: 'DELETE',
                    url: `/events/${item.id}`
                }).then((res) => {
                    setDelLoading(false);
                    getEventsList();
                    window.location.reload(); // Reload the page after deletion
                }).catch((error) => {
                    setDelLoading(false); // Make sure to set loading state to false on error
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error.message,
                        width: 400
                    });
                });
            }
        });
    }

    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{item.title}</td>
            <td>{item.event_time}</td>         
            <td>{item.updated_by }</td>  
            <td>{item.status}</td>
            <td>
                <Link to={`/events/edit/${item.id}`}>
                    <i className="icon-pencil"></i>
                </Link>
                {
                    delLoading ? (
                        <span className='indicator-progress'>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ) : (
                        <button type="button" onClick={handleDelete}>
                            <i className="icon-trash"></i>
                        </button>
                    )
                }
            </td >
            {/* <td>
                <Switch checked={item.status === 'ACTIVE' ? true : false} onClick={handleCheckedClick} loading={loading} />
            </td> */}
        </tr >
    )
}
