import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { apiRequest } from '../../../api';
import './style.css';
import { TableRow } from './TableRow';
import { useDispatch, useSelector } from 'react-redux';
import * as EnquiryReducer from './EnquiryReducer';
import { DatePicker } from 'antd';
import Pagination from '../CommonComponent/Pagination/Pagination';

const { RangePicker } = DatePicker;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 190,
    bgcolor: "background.paper",
    border: "1px solid #ccc",
    boxShadow: 24,
    p: 4
};

const EnquiryList = () => {
    const dispatch = useDispatch();
    const { enquiryList } = useSelector(({ enquiry }) => enquiry);
    // const inputField = useRef(null);
    // const [dateRange, setDateRange] = useState('')
    // const [csvData, setCsvData] = useState([])
    // const [loader, setLoader] = useState(false);
    const [pageListing, setPageListing] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageItems, setCurrentPageItems] = useState([]);
    const pageSize = 10;

    const getFilteredList = (list, page) => {
        let lastPageItems = 0;
        lastPageItems = Math.ceil(list.length % pageSize);
        const arr = list.slice((page*pageSize), (((page)*pageSize) + pageSize));
        setCurrentPageItems(arr);
    };
    const getEnquiryList = () => {
        apiRequest({
            method: "GET",
            url: "enquire-now/getAll"
        }).then(resp => {
            getFilteredList([...resp.data], currentPage);
            setPageListing(resp.data);
            setTotalPageCount(Math.ceil(resp.data.length/pageSize));
            dispatch(EnquiryReducer.actions.enquiryList(resp.data));
        }).catch(error => {
            dispatch(EnquiryReducer.actions.enquiryList([]));
        });
    };

    const handlePage = (page) => {
        setCurrentPage(page);
        getFilteredList([...pageListing], page);
    };
    useEffect(() => {
        getEnquiryList();
    }, []);


    const headers = [
        { label: 'Sr. No.', key: 'srNo'},
        { label: 'ID', key: 'id'},
        { label: 'Email Id', key: 'email_id'},
        { label: 'Name', key: 'name'},
        { label: 'Contact Number', key: 'contact_number'},
        { label: 'Model SKU', key: 'model_sku'},
        { label: 'State', key: 'state'},
        { label: 'City', key: 'city'},
        { label: 'Dealer', key: 'dealer'},
        { label: 'Status', key: 'status'},
        { label: 'Ticket ID', key: 'ticket_id'},
        { label: 'Created At', key: 'created_at'},
        { label: 'Updated At', key: 'updated_at'}
    ];
    const data = [];

    enquiryList.forEach((item, index) => {
        data.push({
            srNo: index + 1,
            id: item.id,
            email_id: item.email_id,
            name: item.name,
            contact_number: item.contact_number.includes('+') ? item.contact_number.substring(3) : item.contact_number,
            model_sku: item.model_sku,
            state: item.state,
            city: item.city,
            dealer: item.dealer,
            status: item.status,
            ticket_id: item.ticket_id,
            created_at: item.createdAt,
            updated_at: item.updatedAt
        })
    })

    const csvReport = {
        data: data,
        headers: headers,
        filename: 'enquiry_now_list.csv'
    }

    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row height-vh event-list-wrapper">
                        <div className="col-12 col-sm-12">
                            <div className='d-flex justify-content-end'>
                                <div>
                                    <button
                                        className='btn btn-secondary p-0 px-2 py-1 mx-2 my-1'
                                    >
                                        <CSVLink {...csvReport}>
                                            <span style={{color: "white"}}>Export</span>
                                        </CSVLink>
                                    </button>
                                </div>
                            </div>
                            <div className="list-group">
                                <div className="list-group-item active" aria-current="true">
                                    Enquiry Lists
                                    <Link to="/enquire-now/add">Create Enquiry</Link>
                                </div>
                                <table className="table table-striped table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Contact Number</th>
                                            <th scope="col">Dealer</th>
                                            <th scope="col">Model SKU</th>
                                            <th scope="col">State</th>
                                            <th scope="col">City</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageItems.map((item, index) => (
                                            <TableRow item={item} index={(currentPage*pageSize)+ index} key={`enquiry__${index}`} />
                                        ))}
                                    </tbody>
                                </table>
                                <Pagination currentPage={currentPage} totalPages={totalPageCount} pageCallBack={handlePage}/>
                            </div>
                            {
                                currentPageItems.length == 0 && (
                                    <div className='text-center'>
                                        -- no record found --
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EnquiryList;