const initialState = {
    accessToken: null
};


export const actionTypes = {
    Login: '[Login] Action'
}
  
export const AuthReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.Login: {
            return {
                ...state,
                accessToken: action.payload
            }
        }
        default: { 
            return state;
        }
    }
}

export const actions = {
    login: (user) => {
        return {
            type: actionTypes.Login,
            payload: user
        }
    }
}