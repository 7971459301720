import React, {useState} from 'react'
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { apiRequest } from '../../../api';
import constant from '../../../config/config';
import * as MediaReducer from './MediaReducer';

export const TableRow = ({item, index}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [delLoading, setDelLoading] = useState(false);

    // const getSliderLists = () => {
    //     apiRequest({
    //         method: "GET",
    //         url: "slider"
    //     }).then(resp => {
    //         dispatch(MediaReducer.actions.sliderList(resp.data));
    //     }).catch(error => {
    //         console.log("get all slider list error", error);
    //     });
    // };

    // const handleCheckedClick = () => {
    //     setLoading(true);
    //     apiRequest({
    //         method: 'PUT',
    //         url: `/slider/${item.id}`,
    //         data: {
    //             ...item,
    //             status: `${item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`
    //         }
    //     }).then(() => {
    //         setLoading(false);
    //         getSliderLists();
    //     }).catch((error) => {
    //         Swal.fire({
    //             icon: "error",
    //             title: "Oops...",
    //             text: error.message,
    //             width: 400
    //         }); 
    //     })
    // }
    const getMediaLists = () => {
        apiRequest({
            method: "GET",
            url: "image/getAll"
        }).then(resp => {
            dispatch(MediaReducer.actions.mediaList(resp.data));
        }).catch(error => {
            console.log(error);
        });
    };

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this item.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setDelLoading(true);
                apiRequest({
                    method: 'DELETE',
                    url: `/image/${item.id}`
                }).then(() => {
                    setDelLoading(false);
                    getMediaLists();
                }).catch((error) => {
                    setDelLoading(false); // Set loading state to false on error
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error.message,
                        width: 400
                    });
                });
            }
        });
    }

    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td className='text-start'>
                {item.title ? item.title : ''}
            </td>
            <td className='text-start'>
                <img src={constant.imageUrl+''+item.full_path} alt='media' width={'100px'} height={'100px'} />
                <p>{constant.imageUrl+''+item.full_path}</p>
            </td>
            <td>
                {
                    delLoading ? (
                        <span className='indicator-progress'>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ) : (
                        <button type="button" onClick={handleDelete}>
                            <i className="icon-trash"></i>
                        </button>
                    )
                }
            </td>
        </tr>
    )
}
