const initialState = {
    mediaList: []
};


export const actionTypes = {
    mediaList: '[mediaList] Action'
}
  
export const MediaReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.mediaList: {
            return {
                ...state,
                mediaList: action.payload.reverse()
            }
        }
        default: { 
            return state;
        }
    }
}

export const actions = {
    mediaList: (mediaList) => {
        return {
            type: actionTypes.mediaList,
            payload: mediaList
        }
    }
}