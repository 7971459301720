import { useState } from 'react';
import moment from 'moment';
import { Switch } from 'antd';
import { apiRequest } from '../../../api';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import * as LoginReducer from './LoginReducer'// Assuming you have a UserReducer file
import { Link } from 'react-router-dom';

export const TableRow = ({ item, index }) => {
    const { id, position_name, updatedAt, sku } = item;  
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    

    const getUserList = () => {
        apiRequest({
            method: "GET",
            url: "users"
        }).then(resp => {
            dispatch(LoginReducer.actions.userList(resp.data));
        }).catch(error => {
            dispatch(LoginReducer.actions.userList([]));
        });
    };
    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleCheckedClick = () => {
        setLoading(true);
        apiRequest({
            method: 'PUT',
            url: `/users/${id}`,
            data: {
                ...item,
                status: `${item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'}`
            }
        }).then(() => {
            setLoading(false);
            getUserList();
        }).catch((error) => {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
                width: 400
            });
        })
    }

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this item.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                apiRequest({
                    method: 'DELETE',
                    url: `/user/${id}`
                }).then(() => {
                    setLoading(false);
                    getUserList();
                }).catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error.message,
                        width: 400
                    });
                });
            }
        });
    }

    return (
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{formatDate(item.lastLogin)}</td>
            {/* <td>{item.password}</td> */}
            {/* <td>
                <Link to={`/users/edit/${sku}`}><i className="icon-pencil"></i></Link>
                {
                    loading ? (
                        <span className='indicator-progress'>
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    ) : (
                        <button type="button" onClick={handleDelete}>
                            <i className="icon-trash"></i>
                        </button>
                    )
                }
            </td> */}
            {/* <td>
                <span className='mx-1'>Active</span>
                <Switch checked={item.status === 'ACTIVE' ? true : false} onClick={handleCheckedClick} loading={loading} />
                <span className='mx-1'>Inactive</span>
            </td> */}
        </tr>
    )
}
