const initialState = {
    enquiryList: []
};


export const actionTypes = {
    enquiryList: '[enquiryList] Action'
}
  
export const EnquiryReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.enquiryList: {
            return {
                ...state,
                enquiryList: action.payload
            }
        }
        default: { 
            return state;
        }
    }
}

export const actions = {
    enquiryList: (enquiryList) => {
        return {
            type: actionTypes.enquiryList,
            payload: enquiryList
        }
    }
}